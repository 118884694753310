import { Control, Controller } from "react-hook-form";

import AsyncSelect from "react-select/async";

import { getAsync } from "src/api/httpClient";
import { CryptoCurrency } from "src/models/FiatToCrypto";
import { SelectItem } from "src/models/SelectItem";

interface FiatCurrencyProps {
  control: Control<any>;
  name: string;
  placeholder?: string;
  getRate: any;
  isDisabled: boolean;
}

const CryptoCurrencySelect = (props: FiatCurrencyProps) => {
  let defaultValue = { value: "", label: "" };

  const fetchCryptoCurrencies = async () => {
    const currencies = await getAsync<CryptoCurrency[]>("crypto-currencies");

    defaultValue.value = currencies.data[0].name;
    defaultValue.label = currencies.data[0].name;
    props.control._formValues.cryptoCurrency = currencies.data[0].name;

    return currencies.data.map<SelectItem>((currency) => {
      return { value: currency.name, label: currency.name };
    });
  };

  return (
    <Controller
      control={props.control}
      name={props.name}
      render={({ field: { onChange } }) => {
        return (
          <AsyncSelect
            cacheOptions
            defaultOptions
            defaultValue={defaultValue}
            loadOptions={fetchCryptoCurrencies}
            isSearchable={false}
            isDisabled={props.isDisabled}
            onChange={(option) => {
              onChange(option ? option.value : null);
              props.getRate();
            }}
          />
        );
      }}
    />
  );
};

export default CryptoCurrencySelect;
