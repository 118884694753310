import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import snsWebSdk from "@sumsub/websdk";

import { getAsync } from "src/api/httpClient";
import { nextStep } from "src/features/steps/stepSlice";
import { useAppDispatch, useAppSelector } from "src/hooks/storeHooks";
import { IdentificationToken } from "src/models/Identification";

const Identification = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const clientId = useAppSelector((state) => state.clients.client?.id);

  const getIdentificationToken = async () => {
    const response = await getAsync<IdentificationToken>(
      `identification/token?userId=${clientId}&level=SelfieLevel`
    );

    return response.data?.token;
  };

  const getAccessToken = async () => {
    return Promise.resolve(await getIdentificationToken());
  };

  useEffect(() => {
    identificationHandler();
  });

  const identificationHandler = async () => {
    const accessToken = await getAccessToken();

    let snsWebSdkInstance = snsWebSdk
      .init(accessToken, () => getAccessToken())
      .withConf({
        lang: "en",
      })
      .withOptions({ addViewportTag: false, adaptIframeHeight: true })
      .on("idCheck.applicantStatus", (payload: any) => {
        try {
          if (payload.reviewResult.reviewAnswer === "GREEN") {
            dispatch(nextStep());
            navigate("/financial-info");
          }
        } catch (error) {}
      })
      .onMessage((handler) => {})
      .on("idCheck.onError", (error) => {})
      .build();

    snsWebSdkInstance.launch("#sumsub-websdk-container");
  };

  return (
    <div className="identification-data-container">
      <div id="sumsub-websdk-container"></div>
    </div>
  );
};

export default Identification;
