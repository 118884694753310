import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { array, string } from "yup";
import { useForm } from "react-hook-form";

import { useAppDispatch, useAppSelector } from "src/hooks/storeHooks";
import { nextStep } from "src/features/steps/stepSlice";
import { getAsync, postAsync, putAsync } from "src/api/httpClient";
import {
  CHANNEL,
  OTP_LENGTH,
  STANDARD_ERROR_MESSAGE,
} from "src/constants/constants";
import Loading from "./Loading/Loading";
import { createClient, getClient } from "src/features/clients/clientsSlice";
import { Identification } from "src/models/Identification";
import NavigateExtension from "src/extensions/navigateExtension";

const schema = yup.object().shape({
  numbers: array().of(string().required()).min(6).max(6).required(),
});

type FormInputs = yup.InferType<typeof schema>;

const OtpConfirmationMerchant = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const otpNumbers = Array.from({ length: OTP_LENGTH }, (x, i) => i);
  const [error, setError] = useState<string>();
  const clientEmail = useAppSelector((store) => store.clients.client?.email);

  const { navigateToNext } = NavigateExtension();

  const fiatToCrypto = useAppSelector(
    (state) => state.fiatToCrypto.fiatToCrypto
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { register, handleSubmit, setValue, setFocus, watch, resetField } =
    useForm<FormInputs>({ resolver: yupResolver(schema) });

  const numbers = watch("numbers")?.filter(
    (data) => data !== "" && data !== undefined
  );

  useEffect(() => {
    handleFocus();
  }, [numbers]);

  const handlePaste = async (event: any) => {
    event.preventDefault();
    const data = await navigator.clipboard.readText();
    let length = 6;

    if (data.length < length) length = data.length;

    for (let i = 0; i < length; i++) {
      if (!Number.isNaN(data[i])) {
        setValue(`numbers.${i}`, data[i]);
      }
    }
    handleFocus();
  };

  const handleBackspace = (event: any) => {
    if (event.code === "Backspace" && numbers.length > 0) {
      event.preventDefault();
      resetField(`numbers.${numbers.length - 1}`);
      setFocus(`numbers.${numbers.length - 1}`);
    }
  };

  const handleFocus = () => {
    let length = 0;

    if (numbers) length = numbers.length !== 6 ? numbers.length : 5;

    setFocus(`numbers.${length}`);
  };

  const handleOnChange = (data: any) => {
    if (numbers.length === 6 && numbers[5] !== "") {
      resetField(`numbers.${5}`);
    }
  };

  useEffect(() => {
    if (numbers && numbers.length < 6) setFocus(`numbers.${numbers.length}`);
  }, [numbers]);

  const resentCode = async () => {
    setIsLoading(true);

    await invalidateOldOtps();

    await resent();

    setIsLoading(false);
  };

  const invalidateOldOtps = async () => {
    await putAsync<any, any>(
      `notifications/email/otp-invalidate?notificationType=email&to=${clientEmail}`,
      null
    );
  };

  const resent = async () => {
    await postAsync<any, any>("notifications/email/otp", {
      notificationType: "email",
      to: clientEmail,
    });
  };

  const onSubmit = async (data: FormInputs) => {
    const otpCode = numbers.join("");

    setIsLoading(true);
    const notification = await getAsync<any>(
      `notifications/email/otp?notificationType=email&to=${clientEmail}&otpCode=${otpCode}`
    );
    setIsLoading(false);

    if (notification.error === STANDARD_ERROR_MESSAGE) return;
    if (notification.error === "OTP_NOT_FOUND") {
      setError("verification code is not correct");
      return;
    }

    dispatch(nextStep());

    setIsLoading(true);
    const client = (await dispatch(getClient(clientEmail ?? "")))
      .payload as any;
    setIsLoading(false);

    if (client.error === STANDARD_ERROR_MESSAGE) return;
    if (client.error === "USER_NOT_FOUND") {
      setIsLoading(true);
      await dispatch(
        createClient({
          email: clientEmail ?? "",
          channel: CHANNEL,
        })
      );
      setIsLoading(false);

      const needsIdentification = await getAsync<Identification>(
        `identification/required?currency=${fiatToCrypto?.fiatCurrency}&amount=${fiatToCrypto?.fiatAmount}&email=${clientEmail}`
      );

      if (needsIdentification?.data.required) {
        navigateToNext("identification");
        return;
      }

      navigateToNext("register-client");
      return;
    }

    const needsIdentification = await getAsync<Identification>(
      `identification/required?currency=${fiatToCrypto?.fiatCurrency}&amount=${fiatToCrypto?.fiatAmount}&email=${clientEmail}`
    );

    if (needsIdentification?.data.required) {
      navigateToNext("identification");
      return;
    }

    if (!client.data.clientVerificationInfo?.completed) {
      navigateToNext("register-client");
      return;
    }

    dispatch(nextStep());
    dispatch(nextStep());
    navigateToNext("exchange-confirmation");
    return;
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <div className="back-third-btn back-btn" onClick={() => navigate(-1)}>
            <img src="/img/arr-left.svg" alt="Arrow Left" />
            Verification
          </div>
          <div className="verif-title">
            Enter verification code sent to your Email
          </div>
          <div className="verif-input">
            {otpNumbers.map((value, index) => (
              <input
                key={index}
                className="magic-numbers"
                type="text"
                {...register(
                  `numbers.${index}`,
                  index === 5 ? { onChange: handleOnChange } : undefined
                )}
                onPaste={handlePaste}
                onFocus={handleFocus}
                onKeyDown={handleBackspace}
              />
            ))}
          </div>
          <div className="resend-code">
            Didn’t recive code?{" "}
            <Link to="" onClick={resentCode}>
              Resent code
            </Link>
            {error && <div className="error-txt">{error}</div>}
          </div>
        </div>
        <div>
          {isLoading && <Loading />}
          {!isLoading && (
            <button className="purp-btn hug-h continue-th" type={"submit"}>
              Continue <img src="/img/arrow-right.svg" alt="Arrow Right" />
            </button>
          )}
          <div className="police-sub-title">
            By continuing you agree to our{" "}
            <Link to="/cookie-policy">Cookie Policy</Link>
          </div>
        </div>
      </form>
    </div>
  );
};

export default OtpConfirmationMerchant;
