import axios from "axios";
import { REACT_APP_BACKEND_API_TOKEN } from "../envVariables";
import { BACKEND } from "../envVariables";

import { STANDARD_ERROR_MESSAGE } from "src/constants/constants";
import { ApiResponse } from "./response";

const BACKEND_URL = BACKEND;
const API_TOKEN = REACT_APP_BACKEND_API_TOKEN;

axios.interceptors.request.use((config) => {
  config.baseURL = BACKEND_URL;
  config.headers["Authorization"] = `Bearer ${API_TOKEN}`;
  //config.withCredentials = true;

  return config;
});



export const getAsync = async function <TOut>(
  path: string
): Promise<ApiResponse<TOut>> {
  try {
    const response = await axios.get<TOut>(path, {withCredentials: true});
    return { error: undefined, statusCode: response.status, data: response.data };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message;
      return { error: errorMessage, statusCode: error.response?.status ?? 500, data: {} as TOut };
    } else {
      return {
        error: STANDARD_ERROR_MESSAGE,
        statusCode: 500,
        data: {} as TOut,
      };
    }
  }
};

export const postAsync = async function <TIn, TOut>(
  path: string,
  data: TIn
): Promise<ApiResponse<TOut>> {
  try {
    const response = await axios.post<TOut>(path, data, {withCredentials: true});
    return { error: undefined, statusCode: response.status, data: response.data };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message;
      return { error: errorMessage, statusCode: error.response?.status ?? 500, data: {} as TOut };
    } else {
      return {
        error: STANDARD_ERROR_MESSAGE,
        statusCode: 500,
        data: {} as TOut,
      };
    }
  }
};

export const putAsync = async function <TIn, TOut>(
  path: string,
  data: TIn
): Promise<ApiResponse<TOut>> {
  try {
    const response = await axios.put<TOut>(path, data, {withCredentials: true});
    return { error: undefined, statusCode: response.status, data: response.data };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message;
      return { error: errorMessage, statusCode: error.response?.status ?? 500, data: {} as TOut };
    } else {
      return {
        error: STANDARD_ERROR_MESSAGE,
        statusCode: 500,
        data: {} as TOut,
      };
    }
  }
};

export const deleteAsync = async function <TOut>(
  path: string
): Promise<ApiResponse<TOut>> {
  try {
    const response = await axios.delete<TOut>(path, {withCredentials: true});
    return { error: undefined, statusCode: response.status, data: response.data };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message;
      return { error: errorMessage,statusCode: error.response?.status ?? 500, data: {} as TOut };
    } else {
      return {
        error: STANDARD_ERROR_MESSAGE,
        statusCode: 500,
        data: {} as TOut,
      };
    }
  }
};
