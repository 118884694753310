import { useEffect } from 'react'
import useFetch from 'src/hooks/useFetch'
import Footer from './Footer'
import { Link } from 'react-router-dom'
import Header from './Header'

const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const { data, loading } = useFetch(`/contact-us?populate=*`)

  return (
    <div>
      <div className="header-bg-hug">
        <div className="content">
          <Header showMain={true} />
          <div className="exchange-box-outside contact-us">
            <div className="contact-info-l">
              <h1>{data?.attributes?.pageHeading}</h1>
              <div className="sub-title">
                {data?.attributes?.pageDescription}
              </div>
              {data?.attributes?.emails.map((item: any, index: any) => (
                <div key={index} className="contact-info-box">
                  <div>{item.title}</div>
                  <div>{item.text}</div>
                </div>
              ))}
            </div>

            <div className="exchange-box-border shape-box">
              <div className="exchange-box shape-box-ins">
                <div className="fields">
                  <div className="fields-top">
                    <label className="field-lbl">Full name</label>
                    <div>Required</div>
                  </div>
                  <input type="text" placeholder="Enter your name" />
                </div>
                <div className="fields">
                  <div className="fields-top">
                    <label className="field-lbl">Email address</label>
                    <div>Required</div>
                  </div>
                  <input type="text" placeholder="Enter your Email" />
                </div>
                <div className="fields txtarea-field">
                  <div className="fields-top">
                    <label className="field-lbl">Message</label>
                    <div>Required</div>
                  </div>
                  <textarea placeholder="Type your message"></textarea>
                </div>
                <label className="custom-checkbox">
                  I agree to <Link to="/terms-of-use">Terms of Use</Link>
                  <input
                    type="checkbox"
                    // checked="checked"
                  />
                  <span className="checkmark"></span>
                </label>
                <button className="purp-btn hug-h">
                  Sent message{' '}
                  <img src="img/arrow-right.svg" alt="Arrow Right" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default ContactUs
