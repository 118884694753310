import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Wallet } from "src/models/Wallet";

type InitialState = {
  loading: boolean;
  wallet: Wallet | null;
  error: string | null;
  lastUpdate: string | null;
};

const initialState: InitialState = {
  loading: false,
  wallet: null,
  error: null,
  lastUpdate: null,
};

const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    initWallet: (state, action: PayloadAction<Wallet>) => {
      state.wallet = action.payload;
      state.lastUpdate = new Date().toString();
    },
  },
});

export const { initWallet } = walletSlice.actions;
export default walletSlice.reducer;
