import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import PendingTransactionIcon from './PendingTransactionIcon'
import SignalRContext from "src/signalRContext";
import {postAsync} from "src/api/httpClient";
import {PaymentInitRequest} from "src/models/PaymentInitRequest";
import {PaymentInitResponse} from "src/models/PaymentInitResponse";

const TransactionResultModal = () =>
{
  const [status, setStatus] = useState<null | string>(null);
  const [transactionId, setTransactionId] = useState<null| string>(null);

  SignalRContext.useSignalREffect(
      "NotifyTransactionEvaluationResultAsync",
      (id: string, status: string) => {
        setStatus(status);
        setTransactionId(id);
      },
      []);

  useEffect(() => {
    (async () => {
      if(status === "Initiated"){
        const url = await createPayment(transactionId!);
        window.location.href = url;
      }
    })()
  }, [status]);

  const createPayment = async (transactionId: string) => {
    const result = await postAsync<PaymentInitRequest, PaymentInitResponse>(
        "payments",
        {
          transactionId,
        }
    );

    return result.data.result.redirectUrl;
  };

  return (
      <ModalContainer>
        <ModalContent>
          <PendingTransactionIcon />
          <Title>The transaction is suspended</Title>

          {!status && (
              <Paragraph>
                Your request will be reviewed shortly by an administrator, sorry for
                the delay.
              </Paragraph>
          )}

          {status && status == "Rejected" && (
              <Paragraph>
                Your Transaction Rejected !
              </Paragraph>
          )}

          {status && status == "Initiated" && (
              <Paragraph>
                Everything is fine ... let's continue !
              </Paragraph>
          )}

        </ModalContent>
      </ModalContainer>
  )
}

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0px 2px 26px 0px rgba(110, 113, 142, 0.27);
`

export const ModalContent = styled.div`
  position: relative;
  background-color: white;
  padding: 60px 30px 0px 30px;
  border-radius: 5px;
  width: 424px;
  height: 518px;
  flex-shrink: 0;
  text-align: center;
  border-radius: 24px;
`

export const Title = styled.h2`
  margin-top: 30px;
  margin-bottom: 20px;
  color: var(--Orange, #f2994a);
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`

export const Paragraph = styled.p`
  font-size: 16px;
  margin-bottom: 20px;
  color: #2f2f2f;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.5;
  margin-bottom: 180px;
`

const Button = styled.button`
  border-radius: 110px;
  background: var(--Primary, #422f71);
  display: flex;
  width: 343px;
  padding: 17px 24px;
  justify-content: center;
  align-items: center;
  gap: 25px;
`
const LinkStyle = styled(Link)`
  color: #fff;
  &:hover {
    color: #fff;
  }
`

export default TransactionResultModal
