import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { merchantTransactionData } from "src/models/merchantTransaction";

type InitialState = {
  loading: boolean;
  merchantTransactionData: merchantTransactionData | null;
  error: string | null;
  lastUpdate: string | null;
};

const initialState: InitialState = {
  loading: false,
  merchantTransactionData: null,
  error: null,
  lastUpdate: null,
};

const merchantTransactionSlice = createSlice({
  name: "merchant",
  initialState,
  reducers: {
    initMerchantTransaction: (
      state,
      action: PayloadAction<merchantTransactionData>
    ) => {
      state.merchantTransactionData = action.payload;
      state.lastUpdate = new Date().toString();
    },
  },
});

export const { initMerchantTransaction } = merchantTransactionSlice.actions;
export default merchantTransactionSlice.reducer;
