import {useEffect, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import DatePickerField from "./DatePickerField";
import CountryPickerField from "./CountryPickerField";
import BirthPlacePickerField from "./BirthPlacePickerField";

import {getAsync} from "src/api/httpClient";
import GenderPickerField from "./GenderPickerField";
import {UpdateClient} from "src/models/RegisterClient";

import {updateClient, updateClientWithPassport} from "src/features/clients/clientsSlice";
import { useAppSelector, useAppDispatch } from "src/hooks/storeHooks";
import { nextStep } from "src/features/steps/stepSlice";
import Loading from "../Loading/Loading";
import {Identification} from "src/models/Identification";

const schema = yup
  .object({
    firstName: yup
      .string()
      .required("first name is a required field")
      .max(20, "cannot be more than 20 characters"),
    lastName: yup
      .string()
      .required("last name is a required field")
      .max(20, "cannot be more than 20 characters"),
    privateNumber: yup.string().max(11),
    gender: yup.number().required(),
    birthDate: yup
      .date()
      .typeError("birth date is a required field")
      .required("birth date is a required field"),
    citizenshipId: yup.string().required(),
    phoneNumber: yup
      .string()
      .typeError("phoneNumber must be a number")
      .max(20)
      .required(),
    birthPlace: yup.string().required(),
    address: yup
      .string()
      .required("address is a required field")
      .min(10)
      .max(100),
    legalAddress: yup
        .string()
        .required()
        .min(10)
        .max(100),
    file: yup
      .mixed()
      .test("fileSize", "The file is too large", (file: any) => {
        if ((file as FileList).length > 0){
          return file[0]?.size <= 2000000;
        }
        return true;
      }).notRequired()
  })
  .required();

type IFormInputs = yup.InferType<typeof schema>;

const RegisterClient = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [needIdentification, setIdentificationNeed] = useState<boolean>(false);
  const fiatToCrypto = useAppSelector(state => state.fiatToCrypto.fiatToCrypto);
  const clientEmail = useAppSelector((store) => store.clients.client?.email);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [file, setFile] = useState<File>();
  const currentClient = useAppSelector((store) => store.clients.client);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
  });
  
  useEffect(() => {
    (async () => {
      const needsIdentification = await getAsync<Identification>(
          `identification/required?currency=${fiatToCrypto?.fiatCurrency}&amount=${fiatToCrypto?.fiatAmount}&email=${clientEmail}`
      );
      setIdentificationNeed(needsIdentification?.data.required)
    })();
  })

  const onSubmit: SubmitHandler<IFormInputs> = async (data) => {
    if (!currentClient || !currentClient.email || (!needIdentification && !file)) return;

    const client: UpdateClient = {
      ...data,
      email: currentClient.email,
    };

    setIsLoading(true);
    const result = needIdentification ? await dispatch(updateClient(client)) : await dispatch(updateClientWithPassport({...client, file: file!}));
    setIsLoading(false);

    if ((result.payload as any).error) return;

    dispatch(nextStep());
   
    if (needIdentification) {
      navigate(location.pathname.replace(/\/([^/]+)\/?$/, "/identification"));
      return;
    }

    // ToDo: Create General function which will calculate next step of wizard !

    dispatch(nextStep());
    navigate("/financial-info");
  };

  const fileSelectedHandler = (event: any) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };
  
  return (
    <div>
      <div className="back-fourth-btn back-btn" onClick={() => navigate(-1)}>
        <img src="/img/arr-left.svg" alt="Arrow Left" />
        personal data
      </div>
      <div className="ps personal-data-container">
        <div className="pers-data-ins">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="fields ">
              <label className="field-lbl">First name</label>
              <div>
                <input
                  {...register("firstName")}
                  placeholder="Enter first name"
                />
              </div>
              <div className="error-txt">{errors.firstName?.message}</div>
            </div>
            <div className="fields ">
              <label className="field-lbl">Last name</label>
              <div>
                <input
                  {...register("lastName")}
                  placeholder="Enter last name"
                />
              </div>
              <div className="error-txt">{errors.lastName?.message}</div>
            </div>
            <div className="fields ">
              <label className="field-lbl">Private number</label>
              <div>
                <input
                  {...register("privateNumber")}
                  type="number"
                  placeholder="Enter personal number"
                />
              </div>
              <div className="error-txt">{errors.privateNumber?.message}</div>
            </div>
            <div className="fields ">
              <label className="field-lbl">Gender</label>
              <div className="select-cont">
                <GenderPickerField
                  placeholder="Choose Gender"
                  control={control}
                  name="gender"
                />
              </div>
              <div className="error-txt">{errors.gender?.message}</div>
            </div>
            <div className="fields ">
              <label className="field-lbl">BirthDate</label>
              <div>
                <DatePickerField
                  placeholder="Enter Birth Date"
                  control={control}
                  name="birthDate"
                />
              </div>
              <div className="error-txt">{errors.birthDate?.message}</div>
            </div>
            <div className="fields ">
              <label className="field-lbl">Citizenship</label>
              <div className="select-cont">
                <CountryPickerField
                  placeholder="Choose citizenship"
                  control={control}
                  name="citizenshipId"
                />
              </div>
              <div className="error-txt">{errors.citizenshipId?.message}</div>
            </div>
            <div className="fields ">
              <label className="field-lbl">Phone Number</label>
              <div>
                <input
                  type="number"
                  placeholder="Enter phone Number"
                  {...register("phoneNumber")}
                />
              </div>
              <div className="error-txt">{errors.phoneNumber?.message}</div>
            </div>
            <div className="fields ">
              <label className="field-lbl">BirthPlace</label>
              <div className="select-cont">
                <BirthPlacePickerField
                  placeholder="Choose birthPlace"
                  control={control}
                  name="birthPlace"
                />
              </div>
              <div className="error-txt">{errors.birthPlace?.message}</div>
            </div>
            <div className="fields ">
              <label className="field-lbl">Actual Address</label>
              <div>
                <input
                  type="text"
                  placeholder="Enter actual address"
                  {...register("address")}
                />
              </div>
              <div className="error-txt">{errors.address?.message}</div>
            </div>

            <div className="fields ">
              <label className="field-lbl">Legal Address</label>
              <div>
                <input
                    type="text"
                    placeholder="Enter Legal address"
                    {...register("legalAddress")}
                />
              </div>
              <div className="error-txt">{errors.legalAddress?.message}</div>
            </div>
            {!needIdentification && (
                <>
                  <label className="field-lbl">Upload Passport</label>
                  <input
                      {...register("file")}
                      type="file"
                      id="images"
                      accept="image/*, application/pdf"
                      onChange={fileSelectedHandler}
                  />
                  <div className="error-txt">{errors.file?.message}</div>
                </>
            )}
            {isLoading && <Loading/>}
            {!isLoading && (
                <button className="purp-btn hug-h continue-fo" type={"submit"}>
                  Continue
                  <img src="/img/arrow-right.svg" alt="Arrow Right"/>
                </button>
            )}
            <div className="police-sub-title">
              By continuing you agree to our{" "}
              <Link to="/cookie-policy">Cookie Policy</Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RegisterClient;
