import { useLocation, useNavigate } from "react-router-dom";

const NavigateExtension = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const navigateToNext = (urlPath: string) => {
    const url =
      location.pathname + (location.pathname.endsWith("/") ? "" : "/");

    const lastSlashIndex = url.lastIndexOf("/");
    const secondLastSlashIndex = url.lastIndexOf("/", lastSlashIndex - 1);

    const modifiedUrl = url.substring(0, secondLastSlashIndex + 1);

    navigate(`${modifiedUrl}${urlPath}`);
  };

  return {
    navigateToNext,
  };
};

export default NavigateExtension;
