import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import useFetch from 'src/hooks/useFetch'

const HelpCenterMobile = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  //tab id
  const tid = useParams().tid

  //faq question id
  const qid = useParams().qid

  const questionIndex = Number(qid)

  const { data, loading } = useFetch(`/help-center-tabs/${tid}?populate=*`)

  const questionData = data?.attributes?.faq[questionIndex]

  return (
    <section>
      <div className="cc-section">
        <div className="content help-center-main">
          <div className="tab-content help-center-tab-cont">
            {/* {data?.attributes.map((item: any, index: any) => (
              <> */}
            <h2>{questionData?.question}</h2>
            <div
              style={{ whiteSpace: 'pre-wrap' }}
              dangerouslySetInnerHTML={{
                __html: questionData?.answer,
              }}
            />
            {/* </>
            ))} */}
          </div>
        </div>
      </div>
    </section>
  )
}

export default HelpCenterMobile
