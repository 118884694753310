import {Controller, Control} from 'react-hook-form'

import AsyncSelect from 'react-select/async'

import {SelectItem} from 'src/models/SelectItem'

interface DropdownPickerProps {
    control: Control<any>,
    name: string,
    placeholder?: string,
    data: Array<string>
}


const BaseDropdownPicker = (props: DropdownPickerProps) => {

    const ItemList = async () => {
        return props.data.map<SelectItem>((item, index) => {
            return {value: index + 1, label: item}
        })
    }

    return (
        <Controller
            control={props.control}
            name={props.name}
            render={({field: {onChange, value}}) => {
                return (
                    <AsyncSelect
                        cacheOptions
                        defaultOptions
                        loadOptions={ItemList}
                        isSearchable={false}
                        value={value ? value.value : null}
                        onChange={(option) => {
                            onChange(option ? option.value : null)
                        }}
                    />
                )
            }}
        />
    )
}

export default BaseDropdownPicker
