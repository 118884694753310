import { Link } from 'react-router-dom'

const FooterBottom = () => {
  return (
    <div className="footer-bottom content">
      <Link to="/">
        <img src="/img/logo-footer.svg" alt="Swapilio" />
      </Link>
      <div>Copyright 2023 Swapilio Limited. All rights reserved.</div>
    </div>
  )
}

export default FooterBottom
