import { REACT_APP_API_URL } from '../envVariables'
import { REACT_APP_API_TOKEN } from '../envVariables'
import axios from "axios";

const CMS_URL = REACT_APP_API_URL
const TOKEN = REACT_APP_API_TOKEN

export const makeRequest = axios.create({
  baseURL: CMS_URL,
  headers: {
    Authorization: "bearer " + TOKEN,
  },
});
