import { Outlet } from "react-router-dom";

const BuyCrypto = () => {
  return (
    <section>
      <div className="gr-section">
        <div className="user-info-line">
          <div className="content">
            <div className="user-line-r">
              <div className="user-line-links active-usr-link">Buy crypto</div>
            </div>
          </div>
        </div>
        <div className="exchange-box-border shape-box shape-box-p">
          <div className="exchange-box shape-box-ins">
            <Outlet />
          </div>
        </div>
      </div>
    </section>
  );
};

export default BuyCrypto;
