import { useState } from 'react'
import { Link } from 'react-router-dom'

const Header: React.FC<{
  showMain?: boolean
}> = ({ showMain }) => {
  const [isNavOpen, setIsNavOpen] = useState(false)

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen)
  }

  const closeNav = () => {
    setIsNavOpen(false)
  }

  return (
    <div className={showMain ? '' : 'header-bg'}>
      <div className={showMain ? '' : 'content'}>
        <div className="header-line">
          <Link to="/" className="h-logo" onClick={closeNav}>
            <img src="/img/logo.svg" alt="Swapilio Logo" />
          </Link>
          <div
            className={!isNavOpen ? 'burger-menu' : 'change-menu burger-menu'}
            onClick={toggleNav}
          >
            <img src="/img/line.svg" alt="Menu" />
            <img src="/img/line.svg" alt="Menu" />
          </div>
          <div className="h-nav desktop-nav">
            <nav>
              <Link to="/about-us" className="nav-a">
                About us
              </Link>
              <Link to="/contact-us" className="nav-a">
                Contact
              </Link>
              <Link to="/help-center" className="nav-a">
                Help Center
              </Link>
            </nav>
            <div className="h-button">
              <Link to="/buy-crypto" className="fill-btn">
                Buy Crypto
              </Link>
            </div>
          </div>
          {isNavOpen && (
            <div className="mobile-nav h-nav">
              <nav onClick={closeNav}>
                <Link to="/about-us" className="nav-a">
                  About us
                </Link>
                <Link to="/contact-us" className="nav-a">
                  Contact
                </Link>
                <Link to="/help-center" className="nav-a">
                  Help Center
                </Link>
              </nav>
              <div onClick={closeNav} className="h-button">
                <Link to="/buy-crypto" className="fill-btn">
                  Buy Crypto
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Header
