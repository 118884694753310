import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { FiatToCrypto } from "src/models/FiatToCrypto";

type InitialState = {
  loading: boolean;
  fiatToCrypto: FiatToCrypto | null;
  error: string | null;
  lastUpdate: string | null;
};

const initialState: InitialState = {
  loading: false,
  fiatToCrypto: null,
  error: null,
  lastUpdate: null,
};

const fiatToCryptoSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {
    initTransaction: (state, action: PayloadAction<FiatToCrypto>) => {
      state.fiatToCrypto = action.payload;
      state.lastUpdate = new Date().toString();
    },
  },
});

export const { initTransaction } = fiatToCryptoSlice.actions;
export default fiatToCryptoSlice.reducer;
