import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import { getAsync } from "src/api/httpClient";
import { TransactionStatus } from "src/models/TransactionStatus";
import Loading from "src/components/Loading/Loading";
import { CryptoTransactionStateEnum } from "src/models/CryptoTransactionStateEnum";

type PaymentResultStatus = "Completed" | "Rejected" | "Pending";

const PaymentResult = () => {
  const { id } = useParams();
  const [status, setStatus] = useState<PaymentResultStatus>("Pending");
  const [tried, setTried] = useState<number>(0);
  const [cryptoAmount, setCryptoAmount] = useState<number>(0);
  const [cryptoCurrency, setCryptoCurrency] = useState<string>("");

  useEffect(() => {
    if (tried >= 10) {
      setStatus("Rejected");
      return;
    }

    if (tried < 10 && status === "Pending") {
      setTimeout(async () => {
        const response = await getAsync<TransactionStatus>(
          `transactions/${id}`
        );

        if (response.error) {
          setStatus("Rejected");
          return;
        }

        setCryptoAmount(response.data.cryptoTransaction.amount);
        setCryptoCurrency(response.data.cryptoTransaction.cryptoCurrency.name);

        switch (response.data.cryptoTransaction.cryptoTransactionStateId) {
          case CryptoTransactionStateEnum.Confirmed:
            setStatus("Completed");
            break;
          case CryptoTransactionStateEnum.Failed:
            setStatus("Rejected");
            break;
          case CryptoTransactionStateEnum.Sent:
            setStatus("Completed");
            break;
          case CryptoTransactionStateEnum.Created:
            setTried((previous) => previous + 1);
            break;
          default:
            setStatus("Rejected");
            break;
        }
      }, 2000 * tried);
    }
  }, [id, tried]);

  if (status === "Pending") {
    return (
      <>
        <div
          style={{
            width: "100%",
            height: "400px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Loading />
        </div>
        <div style={{ textAlign: "center" }}>Pending</div>
      </>
    );
  }

  return (
    <div className="success-step show-success-step">
      <div>
        <div>
          <img
            src={
              status === "Completed" ? "/img/success.svg" : "/img/failed.svg"
            }
            alt={status === "Completed" ? "Status" : "Fail"}
          />
          <div
            className={`status-title ${
              status === "Completed" ? "success" : "failed"
            }`}
          >
            {status === "Completed" ? "Success" : "Failed"}
          </div>
          <div className="status-desc">
            {status === "Completed"
              ? `You have successfully purchased ${cryptoAmount} ${cryptoCurrency}`
              : "Transaction Failed"}
          </div>
        </div>
      </div>
      <div className="mt-5">
        <Link to={"/"} className="bordered-btn hug-h purp-bordered back-h">
          Back to home
        </Link>
      </div>
    </div>
  );
};

export default PaymentResult;
