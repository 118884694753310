import useFetch from 'src/hooks/useFetch'

export const CoinTypes = (data: any) => {
  const { data: coinsList } = useFetch(`/coins?populate=*`)

  return (
    <div className="cards-section">
      <div className="content">
        <h2 className="h2 h2-b">{data?.data?.secondSectionHeading}</h2>
        <div className="sub-title title-b">{data?.data?.secondSectionText}</div>
        <div className="cards">
          {coinsList?.map((item: any, index: any) => (
            <div key={index} className="card-box">
              <img
                src={item.attributes?.logoSvg?.data?.attributes?.url}
                alt={item.attributes.name}
              />
              {item.attributes.name}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default CoinTypes
