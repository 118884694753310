import { Controller, Control } from "react-hook-form";

import AsyncSelect from "react-select/async";

import { getAsync } from "src/api/httpClient";
import { SelectItem } from "src/models/SelectItem";
import { Country } from "src/models/RegisterClient";

interface CountryPickerProps {
  control: Control<any>;
  name: string;
  placeholder?: string;
}

const fetchCountries = async () => {
  const countries = await getAsync<Country[]>("countries");
  return countries.data.map<SelectItem>((country) => {
    return { value: country.id, label: country.name };
  });
};

const CountryPickerField = (props: CountryPickerProps) => {
  return (
    <Controller
      control={props.control}
      name={props.name}
      render={({ field: { onChange, value } }) => {
        return (
          <AsyncSelect
            cacheOptions
            defaultOptions
            loadOptions={fetchCountries}
            isSearchable={false}
            value={value ? value.value : null}
            onChange={(option) => {
              onChange(option ? option.value : null);
            }}
          />
        );
      }}
    />
  );
};

export default CountryPickerField;
