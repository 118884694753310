import * as React from 'react'

function PendingTransactionIcon(props: any) {
  return (
    <svg
      width={80}
      height={80}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M40 53.333S50.668 48 50.668 40v-9.333l-10.666-4-10.667 4V40c0 8 10.667 13.333 10.667 13.333zM40 34.667V40M40 45.333h.013"
        stroke="#F2994A"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.936 8.514a12.433 12.433 0 0116.128 0 14.932 14.932 0 008.498 3.52 12.433 12.433 0 0111.404 11.404 14.932 14.932 0 003.52 8.498 12.433 12.433 0 010 16.128 14.932 14.932 0 00-3.52 8.498 12.433 12.433 0 01-11.404 11.404 14.932 14.932 0 00-8.498 3.52 12.433 12.433 0 01-16.128 0 14.932 14.932 0 00-8.498-3.52 12.433 12.433 0 01-11.404-11.404 14.932 14.932 0 00-3.52-8.498 12.433 12.433 0 010-16.128 14.932 14.932 0 003.52-8.498 12.433 12.433 0 0111.404-11.404 14.932 14.932 0 008.498-3.52z"
        stroke="#F2994A"
        strokeWidth={2.5}
      />
    </svg>
  )
}

export default PendingTransactionIcon
