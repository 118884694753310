import { useEffect } from 'react'
import useFetch from 'src/hooks/useFetch'

const Transactions = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <section>
        <div className="gr-section">
          <div className="user-info-line">
            <div className="content">
              <div className="user-line-n">
                <span>Name</span> Lastname
              </div>
              <div className="user-line-r">
                <a href="buy-crypto.html" className="user-line-links">
                  Buy crypto
                </a>
                <div className="user-line-links active-usr-link">
                  Transactions
                </div>
                {/* <!-- <button className="bordered-btn purp-bordered not-for-mobile">Log out</button> --> */}
              </div>
            </div>
          </div>
          <div className="transaction-main">
            <div className="content">
              <div className="transactions-container">
                <div className="transaction-h">
                  <div className="trns-l">
                    <div>Name</div>
                    <div>Validator</div>
                  </div>
                  <div className="trns-l">Decription</div>
                </div>
                <div className="transaction-body">
                  <div className="ps">
                    <div className="transaction-box">
                      <div className="trns-l">
                        <div>
                          <span className="trns-title-m">Name</span>
                          Transaction Fiat Equivalent Gel
                        </div>
                        <div>
                          <span className="trns-title-m">Validator</span>
                          Number
                        </div>
                      </div>
                      <div className="trns-r">
                        <div className="accordion-item">
                          <div className="accordion-header" id="accordion-h1">
                            <div className="trns-title-m">Decription</div>
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#accordion1"
                              aria-expanded="true"
                              aria-controls="accordion1"
                            >
                              <img src="img/arr-down-b.svg" alt="" />
                            </button>
                          </div>
                          <div
                            id="accordion1"
                            className="accordion-collapse collapse show"
                            aria-labelledby="accordion-h1"
                          >
                            <div className="accordion-body">
                              Lorem ipsum dolor sit amet consectetur. Euismod
                              cras feugiat arcu sed sit rhoncus neque. Mauris
                              elit euismod nisl non arcu facilisis et ornare
                              risus. Fermentum congue interdum amet feugiat
                              purus vulputate viverra auctor in. Ullamcorper
                              urna urna cras mauris nullam. Dignissim dictum
                              quis aliquam quisque et commodo orci nunc lacus.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="transaction-box">
                      <div className="trns-l">
                        <div>
                          <span className="trns-title-m">Name</span>
                          Transaction Fiat Equivalent Gel
                        </div>
                        <div>
                          <span className="trns-title-m">Validator</span>
                          Number
                        </div>
                      </div>
                      <div className="trns-r">
                        <div className="accordion-item">
                          <div className="accordion-header" id="accordion-h2">
                            <div className="trns-title-m">Decription</div>
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#accordion2"
                              aria-expanded="true"
                              aria-controls="accordion2"
                            >
                              <img src="img/arr-down-b.svg" alt="" />
                            </button>
                          </div>
                          <div
                            id="accordion2"
                            className="accordion-collapse collapse"
                            aria-labelledby="accordion-h2"
                          >
                            <div className="accordion-body">
                              Lorem ipsum dolor sit amet consectetur. Euismod
                              cras feugiat arcu sed sit rhoncus neque. Mauris
                              elit euismod nisl non arcu facilisis et ornare
                              risus. Fermentum congue interdum amet feugiat
                              purus vulputate viverra auctor in. Ullamcorper
                              urna urna cras mauris nullam. Dignissim dictum
                              quis aliquam quisque et commodo orci nunc lacus.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="transaction-box">
                      <div className="trns-l">
                        <div>
                          <span className="trns-title-m">Name</span>
                          Transaction Fiat Equivalent Gel
                        </div>
                        <div>
                          <span className="trns-title-m">Validator</span>
                          Number
                        </div>
                      </div>
                      <div className="trns-r">
                        <div className="accordion-item">
                          <div className="accordion-header" id="accordion-h3">
                            <div className="trns-title-m">Decription</div>
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#accordion3"
                              aria-expanded="true"
                              aria-controls="accordion3"
                            >
                              <img src="img/arr-down-b.svg" alt="" />
                            </button>
                          </div>
                          <div
                            id="accordion3"
                            className="accordion-collapse collapse"
                            aria-labelledby="accordion-h3"
                          >
                            <div className="accordion-body">
                              Lorem ipsum dolor sit amet consectetur. Euismod
                              cras feugiat arcu sed sit rhoncus neque. Mauris
                              elit euismod nisl non arcu facilisis et ornare
                              risus. Fermentum congue interdum amet feugiat
                              purus vulputate viverra auctor in. Ullamcorper
                              urna urna cras mauris nullam. Dignissim dictum
                              quis aliquam quisque et commodo orci nunc lacus.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="transaction-box">
                      <div className="trns-l">
                        <div>
                          <span className="trns-title-m">Name</span>
                          Transaction Fiat Equivalent Gel
                        </div>
                        <div>
                          <span className="trns-title-m">Validator</span>
                          Number
                        </div>
                      </div>
                      <div className="trns-r">
                        <div className="accordion-item">
                          <div className="accordion-header" id="accordion-h4">
                            <div className="trns-title-m">Decription</div>
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#accordion4"
                              aria-expanded="true"
                              aria-controls="accordion4"
                            >
                              <img src="img/arr-down-b.svg" alt="" />
                            </button>
                          </div>
                          <div
                            id="accordion4"
                            className="accordion-collapse collapse"
                            aria-labelledby="accordion-h4"
                          >
                            <div className="accordion-body">
                              Lorem ipsum dolor sit amet consectetur. Euismod
                              cras feugiat arcu sed sit rhoncus neque. Mauris
                              elit euismod nisl non arcu facilisis et ornare
                              risus. Fermentum congue interdum amet feugiat
                              purus vulputate viverra auctor in. Ullamcorper
                              urna urna cras mauris nullam. Dignissim dictum
                              quis aliquam quisque et commodo orci nunc lacus.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="transaction-box">
                      <div className="trns-l">
                        <div>
                          <span className="trns-title-m">Name</span>
                          Transaction Fiat Equivalent Gel
                        </div>
                        <div>
                          <span className="trns-title-m">Validator</span>
                          Number
                        </div>
                      </div>
                      <div className="trns-r">
                        <div className="accordion-item">
                          <div className="accordion-header" id="accordion-h5">
                            <div className="trns-title-m">Decription</div>
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#accordion5"
                              aria-expanded="true"
                              aria-controls="accordion5"
                            >
                              <img src="img/arr-down-b.svg" alt="" />
                            </button>
                          </div>
                          <div
                            id="accordion5"
                            className="accordion-collapse collapse"
                            aria-labelledby="accordion-h5"
                          >
                            <div className="accordion-body">
                              Lorem ipsum dolor sit amet consectetur. Euismod
                              cras feugiat arcu sed sit rhoncus neque. Mauris
                              elit euismod nisl non arcu facilisis et ornare
                              risus. Fermentum congue interdum amet feugiat
                              purus vulputate viverra auctor in. Ullamcorper
                              urna urna cras mauris nullam. Dignissim dictum
                              quis aliquam quisque et commodo orci nunc lacus.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="transaction-box">
                      <div className="trns-l">
                        <div>
                          <span className="trns-title-m">Name</span>
                          Transaction Fiat Equivalent Gel
                        </div>
                        <div>
                          <span className="trns-title-m">Validator</span>
                          Number
                        </div>
                      </div>
                      <div className="trns-r">
                        <div className="accordion-item">
                          <div className="accordion-header" id="accordion-h6">
                            <div className="trns-title-m">Decription</div>
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#accordion6"
                              aria-expanded="true"
                              aria-controls="accordion6"
                            >
                              <img src="img/arr-down-b.svg" alt="" />
                            </button>
                          </div>
                          <div
                            id="accordion6"
                            className="accordion-collapse collapse"
                            aria-labelledby="accordion-h6"
                          >
                            <div className="accordion-body">
                              Lorem ipsum dolor sit amet consectetur. Euismod
                              cras feugiat arcu sed sit rhoncus neque. Mauris
                              elit euismod nisl non arcu facilisis et ornare
                              risus. Fermentum congue interdum amet feugiat
                              purus vulputate viverra auctor in. Ullamcorper
                              urna urna cras mauris nullam. Dignissim dictum
                              quis aliquam quisque et commodo orci nunc lacus.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="transaction-box">
                      <div className="trns-l">
                        <div>
                          <span className="trns-title-m">Name</span>
                          Transaction Fiat Equivalent Gel
                        </div>
                        <div>
                          <span className="trns-title-m">Validator</span>
                          Number
                        </div>
                      </div>
                      <div className="trns-r">
                        <div className="accordion-item">
                          <div className="accordion-header" id="accordion-h7">
                            <div className="trns-title-m">Decription</div>
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#accordion7"
                              aria-expanded="true"
                              aria-controls="accordion7"
                            >
                              <img src="img/arr-down-b.svg" alt="" />
                            </button>
                          </div>
                          <div
                            id="accordion7"
                            className="accordion-collapse collapse"
                            aria-labelledby="accordion-h7"
                          >
                            <div className="accordion-body">
                              Lorem ipsum dolor sit amet consectetur. Euismod
                              cras feugiat arcu sed sit rhoncus neque. Mauris
                              elit euismod nisl non arcu facilisis et ornare
                              risus. Fermentum congue interdum amet feugiat
                              purus vulputate viverra auctor in. Ullamcorper
                              urna urna cras mauris nullam. Dignissim dictum
                              quis aliquam quisque et commodo orci nunc lacus.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="transaction-box">
                      <div className="trns-l">
                        <div>
                          <span className="trns-title-m">Name</span>
                          Transaction Fiat Equivalent Gel
                        </div>
                        <div>
                          <span className="trns-title-m">Validator</span>
                          Number
                        </div>
                      </div>
                      <div className="trns-r">
                        <div className="accordion-item">
                          <div className="accordion-header" id="accordion-h8">
                            <div className="trns-title-m">Decription</div>
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#accordion8"
                              aria-expanded="true"
                              aria-controls="accordion8"
                            >
                              <img src="img/arr-down-b.svg" alt="" />
                            </button>
                          </div>
                          <div
                            id="accordion8"
                            className="accordion-collapse collapse"
                            aria-labelledby="accordion-h8"
                          >
                            <div className="accordion-body">
                              Lorem ipsum dolor sit amet consectetur. Euismod
                              cras feugiat arcu sed sit rhoncus neque. Mauris
                              elit euismod nisl non arcu facilisis et ornare
                              risus. Fermentum congue interdum amet feugiat
                              purus vulputate viverra auctor in. Ullamcorper
                              urna urna cras mauris nullam. Dignissim dictum
                              quis aliquam quisque et commodo orci nunc lacus.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="transaction-box">
                      <div className="trns-l">
                        <div>
                          <span className="trns-title-m">Name</span>
                          Transaction Fiat Equivalent Gel
                        </div>
                        <div>
                          <span className="trns-title-m">Validator</span>
                          Number
                        </div>
                      </div>
                      <div className="trns-r">
                        <div className="accordion-item">
                          <div className="accordion-header" id="accordion-h9">
                            <div className="trns-title-m">Decription</div>
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#accordion9"
                              aria-expanded="true"
                              aria-controls="accordion9"
                            >
                              <img src="img/arr-down-b.svg" alt="" />
                            </button>
                          </div>
                          <div
                            id="accordion9"
                            className="accordion-collapse collapse"
                            aria-labelledby="accordion-h9"
                          >
                            <div className="accordion-body">
                              Lorem ipsum dolor sit amet consectetur. Euismod
                              cras feugiat arcu sed sit rhoncus neque. Mauris
                              elit euismod nisl non arcu facilisis et ornare
                              risus. Fermentum congue interdum amet feugiat
                              purus vulputate viverra auctor in. Ullamcorper
                              urna urna cras mauris nullam. Dignissim dictum
                              quis aliquam quisque et commodo orci nunc lacus.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <script>
        {`
        if($(window).width() > 850) {
            $('.ps').perfectScrollbar();
        }
        `}
      </script>
    </>
  )
}

export default Transactions
