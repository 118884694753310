import { useEffect } from 'react'
import useFetch from 'src/hooks/useFetch'

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const { data, loading } = useFetch(`/about-us?populate=*`)

  return (
    <section>
      <div className="cc-section">
        <div className="content about-container">
          <div className="about-box">
            <div className="abt-inside">
              <div className="abt-l">
                <div>{data?.attributes.firstSectionHeading}</div>
              </div>
              <div className="abt-r">
                {data?.attributes.firstSectionText.map((item: any) => (
                  <p key={item.id}>{item.firstSectionParagraph}</p>
                ))}
              </div>
            </div>
          </div>
          <div className="about-box count-boxes">
            <div className="cnt-b">
              <div>{data?.attributes.firstBoxHeading}</div>
              <div>{data?.attributes.firstBoxText}</div>
            </div>
            <div className="cnt-b">
              <div>{data?.attributes.secondBoxHeading}</div>
              <div>{data?.attributes.secondBoxText}</div>
            </div>
            <div className="cnt-b">
              <div>{data?.attributes.thirdBoxHeading}</div>
              <div>{data?.attributes.thirdBoxText}</div>
            </div>
            <div className="cnt-b">
              <div>{data?.attributes.fourthBoxHeading}</div>
              <div>{data?.attributes.fourthBoxText}</div>
            </div>
          </div>
          <div className="about-box reverse-box">
            <div className="abt-inside">
              <div className="abt-l">
                <div>{data?.attributes.thirdSectionHeading}</div>
              </div>
              <div className="abt-r">
                {data?.attributes.thirdSectionText.map((item: any) => (
                  <p key={item.id}>{item.thirdSectionParagraph}</p>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutUs
