import {Navigate, Outlet} from "react-router-dom";
import {Step} from "src/features/steps/stepSlice";
import {useAppSelector} from "src/hooks/storeHooks";

type StepGuardProps = {
    step: Step
}

export const StepGuard = ({step}: StepGuardProps) => {
    const appStep = useAppSelector(store => store.steps.step);
    return  step <= appStep ?  <Outlet /> : <Navigate to={'/'} />
}