import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getAsync } from "src/api/httpClient";
import { STANDARD_ERROR_MESSAGE } from "src/constants/constants";
import { Identification } from "src/models/Identification";

type InitialState = {
  loading: boolean;
  identification: Identification | null;
  error: string | null;
  lastUpdate: string | null;
};

const initialState: InitialState = {
  loading: false,
  identification: null,
  error: null,
  lastUpdate: null,
};

export const needsIdentification = createAsyncThunk(
  "clients/needsIdentification",
  async (data: { currency: string; amount: number; email: string }) => {
    return await getAsync<Identification>(
      `identification/required?currency=${data.currency}&amount=${data.amount}&email=${data.email}`
    );
  }
);

const identificationSlice = createSlice({
  name: "identifications",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(needsIdentification.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(needsIdentification.fulfilled, (state, action) => {
      state.loading = false;
      state.identification = action.payload.data ?? null;
      state.error = null;
      state.lastUpdate = new Date().toString();
    });

    builder.addCase(needsIdentification.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? STANDARD_ERROR_MESSAGE;
      state.lastUpdate = new Date().toString();
    });
  },
});

export default identificationSlice.reducer;
