import { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";

import * as yup from "yup";
import {
  MERCHANT_TOKEN_NOT_FOUND_MESSAGE,
  RATE_REFRESH_INTERVAL,
} from "src/constants/constants";
import { FiatToCryptoRate, MerchantTransaction } from "src/models/FiatToCrypto";
import { useAppDispatch } from "src/hooks/storeHooks";
import { initTransaction } from "src/features/transactions/fiatToCryptoSlice";
import { nextStep } from "src/features/steps/stepSlice";
import { getAsync } from "src/api/httpClient";
import { initFiatToCryptoRate } from "src/features/transactions/fiatToCryptoRateSlice";

import { yupResolver } from "@hookform/resolvers/yup";

import FiatCurrencySelect from "../FiatToCrypto/FiatCurrencySelect";
import CryptoCurrencySelectMerchant from "./CryptoCurrencySelectMerchant";
import { initWallet } from "src/features/wallet/walletSlice";
import { initMerchantTransaction } from "src/features/merchant/merchantTransactionSlice";
import NavigateExtension from "src/extensions/navigateExtension";

const schema = yup
  .object({
    fiatAmount: yup
      .number()
      .required("fiat amount is a required field")
      .max(5000, "maximum amount is 5000"),
    cryptoAmount: yup.number().required("crypto amount is a required field"),
    fiatCurrency: yup.string().required("fiat currency is a required field"),
    cryptoCurrency: yup
      .string()
      .required("crypto currency is a required field"),
  })
  .required();

type IFormInputs = yup.InferType<typeof schema>;

const FiatToCryptoMerchant = () => {
  const [remainingTime, setRemainingTime] = useState(RATE_REFRESH_INTERVAL);
  const [rate, setRate] = useState<number>(0);
  const [fiatToCryptoRate, setFiatToCryptoRate] = useState<FiatToCryptoRate>();
  const [fee, setFee] = useState<number>(0);

  const [detailVisible, setDetailVisible] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const { paramId } = useParams();
  const location = useLocation();

  const { navigateToNext } = NavigateExtension();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    watch,
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      fiatAmount: 300,
      cryptoAmount: 0,
    },
  });

  const fiatAmount = watch("fiatAmount", 0);
  const fiatCurrency = watch("fiatCurrency", "");
  const cryptoAmount = watch("cryptoAmount", 0);
  const cryptoCurrency = watch("cryptoCurrency", "");

  const onSubmit: SubmitHandler<IFormInputs> = async (data) => {
    const transaction = { ...data };
    transaction.fiatAmount = +fiatAmount;

    dispatch(initTransaction({ ...transaction, fiatFee: fee }));
    dispatch(initMerchantTransaction({ requestId: paramId }));

    dispatch(nextStep());

    navigateToNext("client-mail");
  };

  const toggle = () => {
    setDetailVisible(!detailVisible);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRemainingTime((prev) => prev - 1);
    }, 1000);

    if (remainingTime === 0) {
      getFiatRate();
      setRemainingTime(RATE_REFRESH_INTERVAL);
    }

    setFeeHandler(
      fiatToCryptoRate?.commissionRate,
      fiatAmount,
      fiatToCryptoRate?.fiatAccuracy
    );

    return () => clearInterval(intervalId);
  }, [remainingTime]);

  const getFiatRate = async () => {
    const crypto = getValues("cryptoAmount");
    const fiatCurrency = getValues("fiatCurrency");
    const cryptoCurrency = getValues("cryptoCurrency");

    const fiatToCrypto = await getAsync<FiatToCryptoRate>(
      `rate/fiat-to-crypto?fiat=${fiatCurrency}&crypto=${cryptoCurrency}`
    );

    dispatch(initFiatToCryptoRate(fiatToCrypto.data));

    setFiatToCryptoRate(fiatToCrypto.data);

    setRate(fiatToCrypto.data.rate);

    setValue(
      "fiatAmount",
      +(crypto * fiatToCrypto.data.rate).toFixed(
        fiatToCrypto.data.cryptoAccuracy
      )
    );
  };

  useEffect(() => {
    initMerchant();
  }, []);

  const initMerchant = async () => {
    const merchantResponse = await getAsync<MerchantTransaction>(
      `transactions/merchant/${paramId}`
    );

    if (merchantResponse.error === MERCHANT_TOKEN_NOT_FOUND_MESSAGE) {
      redirectWithoutSessionKey();
      return;
    }

    const crypto = merchantResponse.data.amount;
    const cryptoCurrency = merchantResponse.data.cryptoCurrencyId;

    const fiatCurrency = getValues("fiatCurrency");
    const fiatToCrypto = await getAsync<FiatToCryptoRate>(
      `rate/fiat-to-crypto?fiat=${fiatCurrency}&crypto=${cryptoCurrency}`
    );

    dispatch(initFiatToCryptoRate(fiatToCrypto.data));

    dispatch(
      initWallet({ walletAddress: merchantResponse.data.walletAddress })
    );

    setFiatToCryptoRate(fiatToCrypto.data);

    setRate(fiatToCrypto.data.rate);
    setValue("cryptoAmount", crypto);
    setValue(
      "fiatAmount",
      +(crypto * fiatToCrypto.data.rate).toFixed(
        fiatToCrypto.data.cryptoAccuracy
      )
    );
  };

  const redirectWithoutSessionKey = () => {
    const pathArray = location.pathname.split("/");
    pathArray.pop();

    const modifiedPath = pathArray.join("/");
    window.location.href = modifiedPath;
  };

  const setFeeHandler = (
    commissionRate: number | undefined,
    fiatAmount: number,
    fiatAccuracy: number | undefined
  ) => {
    setFee(
      commissionRate ? +(fiatAmount * commissionRate).toFixed(fiatAccuracy) : 0
    );
  };

  const inputs = document.querySelectorAll<HTMLInputElement>(
    'input[type="number"]'
  );

  inputs.forEach((input) => {
    input?.addEventListener("input", (event: Event) => {
      const target = event.target as HTMLInputElement;
      const value = target.value;
      if ((value.includes(".") || value.includes(",")) && value.endsWith("0")) {
        target.value = "";
        target.value = value;
      }
    });
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="fields">
        <label className="field-lbl">I want to spend</label>
        <div className="field-btw">
          <input
            readOnly={true}
            type="number"
            step="any"
            pattern="[0-9]*[,]?[0-9]*"
            inputMode="decimal"
            autoComplete="off"
            style={{ width: "68%" }}
            {...register("fiatAmount")}
            onWheel={(e: any) => e.target.blur()}
            onChange={(e) => {
              const val = e.target.value;
              e.target.value = "";
              e.target.value = val;
              if (val === "") {
                e.target.value = "0";
              }
              const value = e.target.value;
              const roundedValue = parseFloat(value).toFixed(
                fiatToCryptoRate?.fiatAccuracy
              );
              setValue("fiatAmount", +roundedValue);

              const fiat = getValues("fiatAmount");
              setValue(
                "cryptoAmount",
                +(fiat / rate).toFixed(fiatToCryptoRate?.cryptoAccuracy)
              );

              setFeeHandler(
                fiatToCryptoRate?.commissionRate,
                +roundedValue,
                fiatToCryptoRate?.fiatAccuracy
              );
            }}
          />
          <div className="select-cont" style={{ width: "30%" }}>
            <FiatCurrencySelect
              control={control}
              name="fiatCurrency"
              getRate={() => getFiatRate()}
            />
          </div>
        </div>
        <div className="error-txt">{errors.fiatAmount?.message}</div>
        <div className="error-txt">{errors.fiatCurrency?.message}</div>
      </div>
      <div className="fields">
        <label className="field-lbl">I want to buy</label>
        <div className="field-btw">
          <input
            readOnly={true}
            type="number"
            step="any"
            pattern="[0-9]*[,]?[0-9]*"
            inputMode="decimal"
            autoComplete="off"
            style={{ width: "68%" }}
            {...register("cryptoAmount")}
            onWheel={(e: any) => e.target.blur()}
            onChange={(e) => {
              const val = e.target.value;
              e.target.value = "";
              e.target.value = val;
              if (val === "") {
                e.target.value = "0";
              }
              const value = e.target.value;
              const roundedValue = +parseFloat(value).toFixed(
                fiatToCryptoRate?.cryptoAccuracy
              );

              setValue("cryptoAmount", roundedValue);
              setValue(
                "fiatAmount",
                +(roundedValue * rate).toFixed(fiatToCryptoRate?.fiatAccuracy)
              );
            }}
          />
          <div className="select-cont" style={{ width: "30%" }}>
            <CryptoCurrencySelectMerchant
              control={control}
              name="cryptoCurrency"
              getRate={() => getFiatRate()}
              isDisabled={true}
              urlParamId={paramId}
            />
          </div>
        </div>
        <div className="error-txt">{errors.cryptoCurrency?.message}</div>
      </div>
      <div className="fields sum-field">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <label className="field-lbl">Summary</label>
          <p style={{ fontSize: 13 }}>Quote updates in {remainingTime}s</p>
        </div>
        <div className="anm-dp">
          <div className="anm-choose-val" onClick={toggle}>
            <div>
              You get <span>{cryptoAmount ? cryptoAmount : 0}</span>{" "}
              {cryptoCurrency} for
              <span>
                {" "}
                {fee && fiatAmount
                  ? +(+fiatAmount + +fee).toFixed(
                      fiatToCryptoRate?.fiatAccuracy ?? 0
                    )
                  : 0}{" "}
                {fiatCurrency}
              </span>
            </div>
            <img
              src="img/arr-down.svg"
              alt="Arrow Down"
              className={detailVisible ? "anm-arr rotate-arr" : "anm-arr"}
            />
          </div>
          {detailVisible && (
            <div className="anm-hidden-block">
              <div className="get-inf-line">
                <div>{fiatToCryptoRate?.commissionRate} </div>
                <div>
                  {fee && fiatAmount
                    ? (+fiatAmount + +fee).toFixed(
                        fiatToCryptoRate?.fiatAccuracy ?? 0
                      )
                    : 0}{" "}
                  {fiatCurrency}
                </div>
              </div>
              <div className="get-inf-line">
                <div className="fl">
                  Processing fee
                  <div className="info-side">
                    <img src="img/info.svg" alt="Info" />
                    <div className="info-desc">Test</div>
                  </div>
                </div>
                <div>
                  <span>as low as</span>
                  {fee ? fee : 0} {fiatCurrency}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <button type="submit" className="purp-btn hug-h fiat-wrapper">
        Continue <img src="img/arrow-right.svg" alt="Arrow Right" />
      </button>
      <div className="police-sub-title">
        By continuing you agree to our{" "}
        <Link to="/cookie-policy">Cookie Policy</Link>
      </div>
    </form>
  );
};

export default FiatToCryptoMerchant;
