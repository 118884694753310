import { Link } from 'react-router-dom'
import useFetch from 'src/hooks/useFetch'

const FooterMiddle = () => {
  const { data } = useFetch(`/footer`)
  return (
    <div className="footer-mid">
      <div className="content">
        <div className="footer-links">
          <div className="footer-link-col">
            <h6>Company</h6>
            <Link to="/about-us">About us</Link>
            <Link to="/contact-us">Contact</Link>
            <Link to="/help-center">Help Center</Link>
          </div>
          <div className="footer-link-col">
            <h6>Policies</h6>
            <Link to="/terms-of-use">Terms of Use</Link>
            <Link to="/privacy-policy">Privacy Policy</Link>
            <Link to="/cookie-policy">Cookie Policy</Link>
            <Link to="/aml-kyc-policy">AML/KYC Policy</Link>
          </div>
          {/* <div className="footer-link-col">
            <Link to="">Log in</Link>
            <Link to="/buy-crypto">Buy crypto</Link>
          </div>
          <div className="footer-link-col">
            <Link to={data?.attributes.linkedinUrl} target="_blank">
              LinkedIn
            </Link>
            <Link to={data?.attributes.facebookUrl} target="_blank">
              Facebook
            </Link>
            <Link to={data?.attributes.instagramUrl} target="_blank">
              Instagram
            </Link>
          </div> */}
        </div>
        <div className="footer-subsc">
          <div>
            <label>Subscribe to our newsletter</label>
            <div className="subsc-field">
              <input type="text" placeholder="Email" />
              <button>Subscribe</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FooterMiddle
