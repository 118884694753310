import { createSlice } from "@reduxjs/toolkit";

export enum Step {
  Exchange,
  Mail,
  OtpConfirmation,
  UserRegistration,
  Identification,
  FinancialInfo,
  WalletAddress,
  ExchangeConfirmation,
}

type InitialState = {
  step: Step;
};

const initialState: InitialState = {
  step: 0,
};

const stepSlice = createSlice({
  name: "StepSlice",
  initialState,
  reducers: {
    nextStep: (state) => {
      state.step += 1;
    },
  },
});

export const { nextStep } = stepSlice.actions;
export default stepSlice.reducer;
