export const RATE_REFRESH_INTERVAL = 10;
export const STANDARD_ERROR_MESSAGE = "something wrong happened";

export const MERCHANT_TOKEN_NOT_FOUND_MESSAGE = "Transaction Request Not Found";

export const genders = [
  { key: 1, value: "Female" },
  { key: 2, value: "Male" },
  { key: 3, value: "Other" },
];

export const OTP_LENGTH = 6;

export const CHANNEL = "SWAPILIO";
