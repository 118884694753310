import { Link, useLocation, useNavigate } from "react-router-dom";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useAppDispatch } from "src/hooks/storeHooks";
import { nextStep } from "src/features/steps/stepSlice";
import { initClient } from "src/features/clients/clientsSlice";
import { useState } from "react";
import Loading from "./Loading/Loading";
import { Notification } from "src/models/Notification";
import { createNotification } from "src/features/notifications/notificationsSlice";

const schema = yup.object({
  notificationType: yup.string().required().default("Email"),
  to: yup
    .string()
    .required("email is a required field")
    .email("enter correct email format"),
  checkbox: yup.boolean().required("required").isTrue("required"),
});

type FormInputs = yup.InferType<typeof schema>;

const ClientMail = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const {
    formState: { errors },
    register,
    handleSubmit,
  } = useForm<FormInputs>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: FormInputs) => {
    const notification: Notification = { ...data };

    setIsLoading(true);
    await dispatch(createNotification(notification));
    setIsLoading(false);

    dispatch(initClient({ email: data.to }));
    dispatch(nextStep());
    const url = location.pathname.replace(
      /\/([^\/]+)\/?$/,
      "/otp-confirmation"
    );
    navigate(url);
  };

  return (
    <div>
      <div className="back-sec-btn back-btn">
        <img
          src="/img/arr-left.svg"
          alt="Arrow Left"
          onClick={() => navigate(-1)}
        />
      </div>
      <div className="sec-title">
        <div className="title-ins">
          <div className="title-circle"></div>
          Checkout with Swapilio
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="sec-field">
          <label>Enter email</label>
          <div className="field-with-img">
            <img src="/img/email.svg" alt="Email" />
            <input
              type="text"
              placeholder="john.doe@example.com"
              {...register("to")}
            />
          </div>
          <div className="error-txt">{errors.to?.message}</div>
        </div>
        <div className="sec-field">
          <label className="custom-checkbox">
            I agree to <Link to="/terms-of-use">Terms of Use</Link>
            <input type="checkbox" {...register("checkbox")} />{" "}
            <span className="checkmark"></span>
          </label>
          <div className="error-txt">{errors.checkbox?.message}</div>
        </div>
        {isLoading && <Loading />}
        {!isLoading && (
          <button className="purp-btn hug-h continue-s" type={"submit"}>
            Continue
            <img src="/img/arrow-right.svg" alt="Arrow Right" />
          </button>
        )}
      </form>
      <div>
        <div className="police-sub-title">
          By continuing you agree to our{" "}
          <Link to="/cookie-policy">Cookie Policy</Link>
        </div>
      </div>
    </div>
  );
};

export default ClientMail;
