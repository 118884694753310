import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { postAsync } from "src/api/httpClient";
import { STANDARD_ERROR_MESSAGE } from "src/constants/constants";
import { Notification, CreatedOtp } from "src/models/Notification";

type InitialState = {
  loading: boolean;
  createdOtp: CreatedOtp | null;
  error: string | null;
  lastUpdate: string | null;
};

const initialState: InitialState = {
  loading: false,
  createdOtp: null,
  error: null,
  lastUpdate: null,
};

export const createNotification = createAsyncThunk(
  "notifications/createNotification",
  async (notification: Notification) => {
    return await postAsync<Notification, CreatedOtp>(
      "notifications/email/otp",
      notification
    );
  }
);

const notificationsSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createNotification.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(createNotification.fulfilled, (state, action) => {
      state.loading = false;
      state.createdOtp = action.payload.data ?? null;
      state.error = null;
      state.lastUpdate = new Date().toString();
    });

    builder.addCase(createNotification.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? STANDARD_ERROR_MESSAGE;
      state.lastUpdate = new Date().toString();
    });
  },
});

export default notificationsSlice.reducer;
