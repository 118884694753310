import { useEffect, useState } from 'react'

import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { FiatToCryptoRate } from 'src/models/FiatToCrypto'
import { getAsync } from 'src/api/httpClient'
import { RATE_REFRESH_INTERVAL } from 'src/constants/constants'
import CryptoCurrencySelect from './CryptoCurrencySelect'
import FiatCurrencySelect from './FiatCurrencySelect'
import { useAppDispatch } from 'src/hooks/storeHooks'
import { nextStep } from 'src/features/steps/stepSlice'
import { initTransaction } from 'src/features/transactions/fiatToCryptoSlice'
import { initFiatToCryptoRate } from 'src/features/transactions/fiatToCryptoRateSlice'

const schema = yup
  .object({
    fiatAmount: yup
      .number()
      .required('fiat amount is a required field')
      .max(5000, 'maximum amount is 5000'),
    cryptoAmount: yup.number().required('crypto amount is a required field'),
    fiatCurrency: yup.string().required('fiat currency is a required field'),
    cryptoCurrency: yup
      .string()
      .required('crypto currency is a required field'),
  })
  .required()

type IFormInputs = yup.InferType<typeof schema>

const FiatToCrypto = () => {
  const [remainingTime, setRemainingTime] = useState(RATE_REFRESH_INTERVAL)
  const [rate, setRate] = useState<number>(0)
  const [fiatToCryptoRate, setFiatToCryptoRate] = useState<FiatToCryptoRate>()
  const [fee, setFee] = useState<number>(0)

  const [detailVisible, setDetailVisible] = useState<boolean>(false)

  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useAppDispatch()

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    watch,
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      fiatAmount: 300,
      cryptoAmount: 0,
    },
  })

  const fiatAmount = watch('fiatAmount', 0)
  const fiatCurrency = watch('fiatCurrency', '')
  const cryptoAmount = watch('cryptoAmount', 0)
  const cryptoCurrency = watch('cryptoCurrency', '')

  const onSubmit: SubmitHandler<IFormInputs> = async (data) => {
    const transaction = { ...data }
    transaction.fiatAmount = +fiatAmount

    dispatch(initTransaction({ ...transaction, fiatFee: fee }))
    dispatch(nextStep())

    const url = location.pathname + (location.pathname.endsWith('/') ? '' : '/')
    navigate(`${url}client-mail`)
  }

  const getFiatRate = async () => {
    const crypto = getValues('cryptoAmount')
    const fiatCurrency = getValues('fiatCurrency')
    const cryptoCurrency = getValues('cryptoCurrency')

    const fiatToCrypto = await getAsync<FiatToCryptoRate>(
      `rate/fiat-to-crypto?fiat=${fiatCurrency}&crypto=${cryptoCurrency}`
    )

    dispatch(initFiatToCryptoRate(fiatToCrypto.data))
    setFiatToCryptoRate(fiatToCrypto.data)

    setRate(fiatToCrypto.data.rate)
    setValue(
      'fiatAmount',
      +(crypto * fiatToCrypto.data.rate).toFixed(
        fiatToCrypto.data.cryptoAccuracy
      )
    )
  }

  const getRate = async () => {
    const fiat = getValues('fiatAmount')
    const fiatCurrency = getValues('fiatCurrency')
    const cryptoCurrency = getValues('cryptoCurrency')
    const fiatToCrypto = await getAsync<FiatToCryptoRate>(
      `rate/fiat-to-crypto?fiat=${fiatCurrency}&crypto=${cryptoCurrency}`
    )

    dispatch(initFiatToCryptoRate(fiatToCrypto.data))
    setFiatToCryptoRate(fiatToCrypto.data)

    setRate(fiatToCrypto.data.rate)
    setValue(
      'cryptoAmount',
      +(fiat / fiatToCrypto.data.rate).toFixed(fiatToCrypto.data.cryptoAccuracy)
    )
  }

  const toggle = () => {
    setDetailVisible(!detailVisible)
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRemainingTime((prev) => prev - 1)
    }, 1000)

    if (remainingTime === 0) {
      getRate()
      setRemainingTime(RATE_REFRESH_INTERVAL)
    }

    setFee(
      fiatToCryptoRate?.commissionRate
        ? +(fiatAmount * fiatToCryptoRate?.commissionRate).toFixed(
            fiatToCryptoRate.fiatAccuracy
          )
        : 0
    )

    return () => clearInterval(intervalId)
  }, [remainingTime])

  useEffect(() => {
      (async () => {
          await getRate();
      })();

    setFee(
      fiatToCryptoRate?.commissionRate
        ? +(fiatAmount * fiatToCryptoRate?.commissionRate).toFixed(
            fiatToCryptoRate.fiatAccuracy
          )
        : 0
    )
  }, [])

    // ToDo: Remove
  // const inputs = document.querySelectorAll<HTMLInputElement>(
  //   'input[type="number"]'
  // )
    
  // inputs.forEach((input) => {
  //   input?.addEventListener('input', (event: Event) => {
  //     const target = event.target as HTMLInputElement
  //     const intlNumber = new Intl.NumberFormat('en-US')
  //     const value = intlNumber.format(parseFloat(target.value)).toString()
  //     if ((value.includes('.') || value.includes(',')) && value.endsWith('0')) {
  //       target.value = ''
  //       target.value = value
  //     }
  //   })
  // })

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="fields">
        <label className="field-lbl">I want to spend</label>
        <div className="field-btw">
          <input
            type="number"
            step="any"
            pattern="^[+]?\d*\.?\d+$"
            inputMode="decimal"
            autoComplete="off"
            style={{ width: '68%' }}
            {...register('fiatAmount')}
            onWheel={(e: any) => e.target.blur()}
            onChange={(e) => {
                
              if (e.target.value === '') {
                  e.target.value = '0'
              }
              
              const value = e.target.value;
              const roundedValue = parseFloat(value).toFixed(
                fiatToCryptoRate?.fiatAccuracy
              );
              
              setValue('fiatAmount', +roundedValue);
              const fiat = getValues('fiatAmount');
              
              setValue(
                'cryptoAmount',
                +(fiat / rate).toFixed(fiatToCryptoRate?.cryptoAccuracy)
              );

              setFee(
                fiatToCryptoRate?.commissionRate
                  ? +(+roundedValue * fiatToCryptoRate?.commissionRate).toFixed(
                      fiatToCryptoRate.fiatAccuracy
                    )
                  : 0
              );
              e.currentTarget.type = 'text';
              e.currentTarget.setSelectionRange(value.length, value.length);
              e.currentTarget.type = 'number'
            }}
          />
          <div className="select-cont" style={{ width: '30%' }}>
            <FiatCurrencySelect
              control={control}
              name="fiatCurrency"
              getRate={() => getRate()}
            />
          </div>
        </div>
        <div className="error-txt">{errors.fiatAmount?.message}</div>
        <div className="error-txt">{errors.fiatCurrency?.message}</div>
      </div>
      <div className="fields">
        <label className="field-lbl">I want to buy</label>
        <div className="field-btw">
          <input
            type="number"
            step="any"
            pattern="^[+]?\d*\.?\d+$"
            inputMode="decimal"
            autoComplete="off"
            style={{ width: '68%' }}
            {...register('cryptoAmount')}
            onWheel={(e: any) => e.target.blur()}
            onChange={(e) => {
              if (e.target.value === '') {
                e.target.value = '0'
              }
              
              const value = e.target.value;
              const roundedValue = +parseFloat(value).toFixed(
                fiatToCryptoRate?.cryptoAccuracy
              );

              setValue('cryptoAmount', roundedValue);
              setValue(
                'fiatAmount',
                +(roundedValue * rate).toFixed(fiatToCryptoRate?.fiatAccuracy)
              );

              e.currentTarget.type = 'text';
              e.currentTarget.setSelectionRange(value.length, value.length);
              e.currentTarget.type = 'number'
            }}
          />
          <div className="select-cont" style={{ width: '30%' }}>
            <CryptoCurrencySelect
              control={control}
              name="cryptoCurrency"
              getRate={() => getFiatRate()}
              isDisabled={false}
            />
          </div>
        </div>
        <div className="error-txt">{errors.cryptoCurrency?.message}</div>
      </div>
      <div className="fields sum-field">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <label className="field-lbl">Summary</label>
          <p style={{ fontSize: 13 }}>Quote updates in {remainingTime}s</p>
        </div>
        <div className="anm-dp">
          <div className="anm-choose-val" onClick={toggle}>
            <div>
              You get <span>{cryptoAmount ? cryptoAmount : 0}</span>{' '}
              {cryptoCurrency} for
              <span>
                {' '}
                {fee && fiatAmount
                  ? +(+fiatAmount + +fee).toFixed(
                      fiatToCryptoRate?.fiatAccuracy ?? 0
                    )
                  : 0}{' '}
                {fiatCurrency}
              </span>
            </div>
            <img
              src="/img/arr-down.svg"
              alt="Arrow Down"
              className={detailVisible ? 'anm-arr rotate-arr' : 'anm-arr'}
            />
          </div>
          {detailVisible && (
            <div className="anm-hidden-block">
              <div className="get-inf-line">
                <div>{fiatToCryptoRate?.commissionRate} </div>
                <div>
                  {fee && fiatAmount
                    ? (+fiatAmount + +fee).toFixed(
                        fiatToCryptoRate?.fiatAccuracy ?? 0
                      )
                    : 0}{' '}
                  {fiatCurrency}
                </div>
              </div>
              <div className="get-inf-line">
                <div className="fl">
                  Processing fee
                  <div className="info-side">
                    <img src="/img/info.svg" alt="Info" />
                    <div className="info-desc">Test</div>
                  </div>
                </div>
                <div>
                  <span>as low as</span>
                  {fee ? fee : 0} {fiatCurrency}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <button type="submit" className="purp-btn hug-h fiat-wrapper">
        Continue <img src="/img/arrow-right.svg" alt="Arrow Right" />
      </button>
      <div className="police-sub-title">
        By continuing you agree to our{' '}
        <Link to="/cookie-policy">Cookie Policy</Link>
      </div>
    </form>
  )
}

export default FiatToCrypto
