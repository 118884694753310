import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { FiatToCryptoRate } from "src/models/FiatToCrypto";

type InitialState = {
  loading: boolean;
  fiatToCryptoRate: FiatToCryptoRate | null;
  error: string | null;
  lastUpdate: string | null;
};

const initialState: InitialState = {
  loading: false,
  fiatToCryptoRate: null,
  error: null,
  lastUpdate: null,
};

const fiatToCryptoRateSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {
    initFiatToCryptoRate: (state, action: PayloadAction<FiatToCryptoRate>) => {
      state.fiatToCryptoRate = action.payload;
      state.lastUpdate = new Date().toString();
    },
  },
});

export const { initFiatToCryptoRate } = fiatToCryptoRateSlice.actions;
export default fiatToCryptoRateSlice.reducer;
