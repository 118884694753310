const Advantages = (data: any) => {
  return (
    <div className="advantage-section">
      <div className="advantage-vect"></div>
      <div className="adv-z">
        <h2 className="h2 h2-w">{data?.data?.thirdSectionHeading}</h2>
        <div className="sub-title title-wx">{data?.data?.thirdSectionText}</div>
        <div className="content adv-container">
          {data?.data?.mobileBoxes?.map((item: any, index: any) => (
            <div className="adv-box-out" key={index}>
              <div className="adv-box-ins">
                <div className="adv-box-title">{item.boxTitle}</div>
                <div>{item.boxDescription}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Advantages
