import {useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {useForm, SubmitHandler} from 'react-hook-form'

import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'

import BaseDropdownPicker from './BaseDropdownPicker'
import {useAppSelector, useAppDispatch} from 'src/hooks/storeHooks'
import {nextStep} from 'src/features/steps/stepSlice'
import Loading from '../Loading/Loading'
import {FinancialInfo} from 'src/models/FinancialInfo'
import {createFinancialInfo} from 'src/features/financialInfo/financialInfoSlice'

const schema = yup
    .object({
        relationshipPurpose: yup.number().required(),
        sourceOfIncome: yup.number().required(),
        monthlyIncomeRange: yup.number().required(),
        exceptedTransactionsQuantity: yup.number().required(),
        expectedVolume: yup.number().required(),
        clientsWorkplace: yup.string().required(),
    })
    .required()

type IFormInputs = yup.InferType<typeof schema>

interface Citizenship {
    en: string
    ge: string
    from: number | null
    to: number | null
}

interface transactionQuantityData {
    text: string,
    from: number | null,
    to: number | null
}

const RegisterFinancialInfo = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const client = useAppSelector((store) => store.clients.client)!
    const birthPlace = client!.birthPlace;

    const relationalPurpose: Array<string> = ["Exchange Crypto", "Withdraw Crypto", "Buy Crypto"];
    const sourceOfIncome: Array<string> = ["Salary", "Saving", "Income from rend", "Dividends", "Inheritance", "Loan/Borrowed Funds", "Income from real estate sale", "Other"];

    const expectedVolume: Array<Citizenship> = [
        {en: "<= 3000 EUR", ge: "<= 10000 GEL", from: null, to: birthPlace === "GE" ? 10000 : 3000},
        {
            en: "3000 - 15000 EUR",
            ge: "10000 - 50000 GEL",
            from: birthPlace === "GE" ? 10000 : 50000,
            to: birthPlace === "GE" ? 10000 : 3000
        },
        {en: "> 15000 EUR", ge: "> 50000 GEL", from: birthPlace === "GE" ? 15000 : 50000, to: null}
    ];

    const monthlyIncome: Array<Citizenship> = [
        {en: "<= 300 EUR", ge: "<= 1000 GEL", from: null, to: birthPlace === "GE" ? 1000 : 3000},
        {
            en: "300 - 1500 EUR",
            ge: "1000 - 5000 GEL",
            from: birthPlace === "GE" ? 1000 : 5000,
            to: birthPlace === "GE" ? 5000 : 1500
        },
        {
            en: "1500 - 3000 EUR",
            ge: "5000 - 10 000 GEL",
            from: birthPlace === "GE" ? 5000 : 1500,
            to: birthPlace === "GE" ? 10000 : 3000
        },
        {en: "> 3000 EUR", ge: "> 10 000 GEL", from: birthPlace === "GE" ? 10000 : 3000, to: null}
    ];

    const transactionQuantity: Array<transactionQuantityData> = [
        {text: "<= 30", from: null, to: 30},
        {text: "30 - 50", from: 30, to: 50},
        {text: "> 50", from: 50, to: null}
    ];

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const {
        control,
        register,
        handleSubmit,
        formState: {errors},
    } = useForm<IFormInputs>({
        resolver: yupResolver(schema),
    })

    const onSubmit: SubmitHandler<IFormInputs> = async (data: IFormInputs) => {
        const financialInfo: FinancialInfo = {
            clientId: client.id,
            currencyId: birthPlace === "GE" ? "GEL" : "EUR",
            workPlace: data.clientsWorkplace,
            relationshipPurpose: relationalPurpose[data.relationshipPurpose - 1].replace(' ', ''),
            sourceOfIncome: sourceOfIncome[data.sourceOfIncome - 1],
            expectedVolume: {
                from: expectedVolume[data.expectedVolume - 1].from,
                to: expectedVolume[data.expectedVolume - 1].to
            },
            monthlyIncomeRange: {from: transactionQuantity[data.exceptedTransactionsQuantity - 1].from, to: transactionQuantity[data.exceptedTransactionsQuantity - 1].to},
            exceptedTransactionsQuantity: {
                from: monthlyIncome[data.expectedVolume - 1].from,
                to: monthlyIncome[data.expectedVolume - 1].to
            }
        }

        setIsLoading(true);
        const result = await dispatch(createFinancialInfo(financialInfo));
        setIsLoading(false);

        if ((result.payload as any).error) {
            return;
        }

        dispatch(nextStep());
        navigate('/wallet');
    }

    return (
        <div>
            <div className="back-fourth-btn back-btn" onClick={() => navigate(-1)}>
                <img src="/img/arr-left.svg" alt="Arrow Left"/>
                {'Financial information'}
            </div>
            <div className="ps personal-data-container">
                <div className="pers-data-ins">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="fields ">
                            <label className="field-lbl">
                                The purpose of establishing a business relationship
                            </label>
                            <div>
                                <BaseDropdownPicker
                                    data={relationalPurpose}
                                    placeholder="Choose Option"
                                    control={control}
                                    name="relationshipPurpose"
                                />
                            </div>
                            <div className="error-txt">
                                {errors.relationshipPurpose?.message}
                            </div>
                        </div>
                        <div className="fields ">
                            <label className="field-lbl">Source of Income</label>
                            <div>
                                <BaseDropdownPicker
                                    data={sourceOfIncome}
                                    placeholder="Choose Option"
                                    control={control}
                                    name="sourceOfIncome"
                                />
                            </div>
                            <div className="error-txt">{errors.sourceOfIncome?.message}</div>
                        </div>
                        <div className="fields ">
                            <label className="field-lbl">Monthly income</label>
                            <div>
                                <BaseDropdownPicker
                                    data={monthlyIncome.map(ranges => birthPlace === "GE" ? ranges.ge : ranges.en)}
                                    placeholder="Choose Option"
                                    control={control}
                                    name="monthlyIncomeRange"
                                />
                            </div>
                            <div className="error-txt">
                                {errors.monthlyIncomeRange?.message}
                            </div>
                        </div>
                        <div className="fields ">
                            <label className="field-lbl">
                                Expected frequency of operations{' '}
                                <i style={{opacity: '0.5'}}>(per month)</i>
                            </label>
                            <div>
                                <BaseDropdownPicker
                                    data={transactionQuantity.map(t => t.text)}
                                    placeholder="Choose Option"
                                    control={control}
                                    name="exceptedTransactionsQuantity"
                                />
                            </div>
                            <div className="error-txt">
                                {errors.exceptedTransactionsQuantity?.message}
                            </div>
                        </div>
                        <div className="fields ">
                            <label className="field-lbl">
                                The volume of expected operations{' '}
                                <i style={{opacity: '0.5'}}>(per month)</i>
                            </label>
                            <div>
                                <BaseDropdownPicker
                                    data={expectedVolume.map(ranges => birthPlace === "GE" ? ranges.ge : ranges.en)}
                                    placeholder="Choose Option"
                                    control={control}
                                    name="expectedVolume"
                                />
                            </div>
                            <div className="error-txt">{errors.expectedVolume?.message}</div>
                        </div>
                        <div className="fields ">
                            <label className="field-lbl">Client's workplace</label>
                            <div>
                                <input
                                    style={{width: '100%', padding: '10px', minHeight: '100px'}}
                                    placeholder="Write the text"
                                    {...register('clientsWorkplace')}
                                />
                            </div>
                            <div className="error-txt">
                                {errors.clientsWorkplace?.message}
                            </div>
                        </div>
                        {isLoading && <Loading/>}
                        {!isLoading && (
                            <button className="purp-btn hug-h continue-fo">
                                Continue
                                <img src="/img/arrow-right.svg" alt="Arrow Right"/>
                            </button>
                        )}
                        <div className="police-sub-title">
                            By continuing you agree to our{' '}
                            <Link to="/cookie-policy">Cookie Policy</Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default RegisterFinancialInfo
