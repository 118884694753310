import { Controller, Control } from "react-hook-form";

import AsyncSelect from "react-select/async";

import { SelectItem } from "src/models/SelectItem";
import { genders } from "src/constants/constants";

interface GenderPickerProps {
  control: Control<any>;
  name: string;
  placeholder?: string;
}

const GenderList = async () => {
  return genders.map<SelectItem>((Gender) => {
    return { value: Gender.key, label: Gender.value };
  });
};

const GenderPickerField = (props: GenderPickerProps) => {
  return (
    <Controller
      control={props.control}
      name={props.name}
      render={({ field: { onChange, value } }) => {
        return (
          <AsyncSelect
            cacheOptions
            defaultOptions
            loadOptions={GenderList}
            isSearchable={false}
            value={value ? value.value : null}
            onChange={(option) => {
              onChange(option ? option.value : null);
            }}
          />
        );
      }}
    />
  );
};

export default GenderPickerField;
