import { Controller, Control } from "react-hook-form";

import { DatePicker } from "antd";
import dayjs from "dayjs";

interface DatePickerProps {
  control: Control<any>;
  name: string;
  placeholder?: string;
}

const DatePickerField = (props: DatePickerProps) => {
  return (
    <Controller
      control={props.control}
      name={props.name}
      render={({ field: { onChange, value } }) => {
        return (
          <DatePicker
            className="field-date-picker"
            placeholder={props.placeholder}
            value={value ? dayjs(value.valueOf()) : null}
            onChange={(date) => {
              onChange(date ? date : null);
            }}
            disabledDate={(current) => {
              let currentDate = dayjs().add(-18, "years").format("YYYY-MM-DD");
              return current && current > dayjs(currentDate, "YYYY-MM-DD");
            }}
          />
        );
      }}
    />
  );
};

export default DatePickerField;
