import { configureStore } from "@reduxjs/toolkit";

import clientsReducer from "../features/clients/clientsSlice";
import stepReducer from "src/features/steps/stepSlice";
import identificationSlice from "src/features/clients/identificationSlice";
import fiatToCryptoSlice from "src/features/transactions/fiatToCryptoSlice";
import fiatToCryptoRateSlice from "src/features/transactions/fiatToCryptoRateSlice";
import walletSlice from "src/features/wallet/walletSlice";
import notificationsSlice from "src/features/notifications/notificationsSlice";
import merchantTransaction from "src/features/merchant/merchantTransactionSlice";
import financialInfoReducer from "src/features/financialInfo/financialInfoSlice";


export const store = configureStore({
  reducer: {
    clients: clientsReducer,
    steps: stepReducer,
    identification: identificationSlice,
    fiatToCrypto: fiatToCryptoSlice,
    fiatToCryptoRate: fiatToCryptoRateSlice,
    wallet: walletSlice,
    notifications: notificationsSlice,
    merchant: merchantTransaction,
    financialInfo: financialInfoReducer,
  },
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
