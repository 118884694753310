import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getAsync, postAsync, putAsync } from "src/api/httpClient";
import { STANDARD_ERROR_MESSAGE } from "src/constants/constants";
import {FinancialInfo, InitFinancialInfo} from "src/models/FinancialInfo";

type InitialState = {
  loading: boolean;
  financialInfo: FinancialInfo | null;
  error: string | null;
  lastUpdate: string | null;
};

const initialState: InitialState = {
  loading: false,
  financialInfo: null,
  error: null,
  lastUpdate: null,
};

// export const getFinancialInfo = createAsyncThunk(
//   "financial-info/getFinancialInfo",
//   async (email: string) => {
//     return await getAsync<FinancialInfo>(``);
//   }
// );

export const createFinancialInfo = createAsyncThunk(
  "clients/financial-info",
  async (initFinancialInfo: InitFinancialInfo) => {
    return await postAsync<InitFinancialInfo, FinancialInfo>("clients/financial-info", initFinancialInfo);
  }
);

// export const updateFinancialInfo = createAsyncThunk(
//   "financial-info/updateFinancialInfo",
//   async (updateFinancialInfo: UpdateFinancialInfo) => {
//     return await putAsync<UpdateFinancialInfo, any>("clients", updateFinancialInfo);
//   }
// );


const financialInfoSlice = createSlice({
  name: "financialInfo",
  initialState,
  reducers: {
    initFinancialInfo: (state, action: PayloadAction<FinancialInfo>) => {
      state.financialInfo = action.payload;
      state.lastUpdate = new Date().toString();
    },
    resetFinancialInfo: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createFinancialInfo.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(createFinancialInfo.fulfilled, (state, action) => {
      state.loading = false;
      state.financialInfo = action.payload.data ?? null;
      state.error = null;
      state.lastUpdate = new Date().toString();
    });

    builder.addCase(createFinancialInfo.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? STANDARD_ERROR_MESSAGE;
      state.lastUpdate = new Date().toString();
    });

    // builder.addCase(getFinancialInfo.pending, (state) => {
    //   state.loading = true;
    // });

    // builder.addCase(getFinancialInfo.fulfilled, (state, action) => {
    //   state.loading = false;
    //   state.financialInfo = action.payload.data ?? null;
    //   state.error = null;
    //   state.lastUpdate = new Date().toString();
    // });

    // builder.addCase(getFinancialInfo.rejected, (state, action) => {
    //   state.loading = false;
    //   state.error = action.error.message ?? STANDARD_ERROR_MESSAGE;
    //   state.lastUpdate = new Date().toString();
    // });

    // builder.addCase(updateFinancialInfo.pending, (state) => {
    //   state.loading = true;
    // });

    // builder.addCase(updateFinancialInfo.fulfilled, (state, action) => {
    //   state.loading = false;
    //   state.error = null;
    //   state.lastUpdate = new Date().toString();
    // });

    // builder.addCase(updateFinancialInfo.rejected, (state, action) => {
    //   state.loading = false;
    //   state.error = action.error.message ?? STANDARD_ERROR_MESSAGE;
    //   state.lastUpdate = new Date().toString();
    // });
  },
});

export const { resetFinancialInfo, initFinancialInfo} = financialInfoSlice.actions;
export default financialInfoSlice.reducer;
