import {ModalContainer, ModalContent, Title} from "src/components/TransactionResultModal";

const TransactionRejectModal = () => {
    return (
        <ModalContainer>
            <ModalContent>
                <img src={"/img/failed.svg"} alt={'Reject'}/>
                <Title>Your Transaction is rejected</Title>
            </ModalContent>
        </ModalContainer>
    )
}


export default TransactionRejectModal;