const BACKEND: string = window._env_?.BACKEND || "";
const REACT_APP_API_TOKEN: string = window._env_?.REACT_APP_API_TOKEN || "";
const REACT_APP_API_URL: string = window._env_?.REACT_APP_API_URL || "";
const REACT_APP_BACKEND_API_TOKEN: string =
  window._env_?.REACT_APP_BACKEND_API_TOKEN || "";
const REACT_APP_APPINSIGHTS_INSTRUMENTATIONKEY: string =
  window._env_?.REACT_APP_APPINSIGHTS_INSTRUMENTATIONKEY || "";

const REACT_APP_BACKEND_REALTIME_URL: string =
    window._env_?.REACT_APP_BACKEND_REALTIME_URL || "";

export {
  BACKEND,
  REACT_APP_API_TOKEN,
  REACT_APP_API_URL,
  REACT_APP_BACKEND_API_TOKEN,
  REACT_APP_APPINSIGHTS_INSTRUMENTATIONKEY,
  REACT_APP_BACKEND_REALTIME_URL
};
