import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import useFetch from 'src/hooks/useFetch'

const HelpCenter = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const { data, loading } = useFetch(`/help-center-tabs?populate=*`)

  const navigate = useNavigate()

  return (
    <section>
      <div className="cc-section">
        <div className="content help-center-main">
          <div className="help-center-h">
            <div className="tabs-header">
              <ul className="nav nav-tabs" role="tablist">
                {/* <div className="slider"></div> */}
                {data?.map((item: any, index: any) => (
                  <li className="nav-item" key={index}>
                    <a
                      className={`${
                        index === 0 ? 'nav-link active' : 'nav-link'
                      }`}
                      data-bs-toggle="tab"
                      href={'#' + item.attributes.tabName}
                    >
                      {item.attributes.tabName}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            {/* <div className="search-side fields">
              <img src="img/search.svg" alt="Search" />
              <input type="text" placeholder="Search" />
            </div> */}
          </div>

          <div className="tab-content">
            {data?.map((item: any, index: any) => (
              <div
                id={item.attributes.tabName}
                key={index}
                className={`${
                  index === 0 ? 'tab-pane active' : 'tab-pane fade'
                }`}
              >
                <br />
                <div className="help-center-titles">
                  <h1>{item.attributes.tabHeading}</h1>
                  <div className="sub-title">
                    {item.attributes.tabDescription}
                  </div>
                </div>
                <div className="help-center-tabs">
                  <div className="ul-out">
                    {/* <!-- ul for Desktop --> */}
                    <ul role="tablist" className="info-tabs-h not-for-mobile">
                      {item?.attributes?.faq?.map(
                        (faqItem: any, faqIndex: any) => (
                          <li key={faqIndex} className="nav-item">
                            <a
                              className={`${
                                faqIndex === 0 ? 'nav-link active' : 'nav-link'
                              }`}
                              data-bs-toggle="tab"
                              href={'#q' + item.id + faqItem.id}
                            >
                              {faqItem.question}
                            </a>
                          </li>
                        )
                      )}
                    </ul>
                    {/* mobile */}
                    <ul role="tablist" className="info-tabs-h only-for-mobile">
                      {item?.attributes?.faq?.map(
                        (faqItem: any, faqIndex: any) => (
                          <li key={faqIndex} className="nav-item">
                            <a
                              className={`${
                                faqIndex === 0 ? 'nav-link active' : 'nav-link'
                              }`}
                              // href='/help-center//2'
                              onClick={() =>
                                navigate(`/help-center/${item.id}/${faqIndex}`)
                              }
                            >
                              {faqItem.question}
                            </a>
                          </li>
                        )
                      )}
                    </ul>
                    {/* <!-- show this >> see more << if li is ore than 9  --> */}
                    {/* <span className="see-more">See more</span> */}
                  </div>
                  <div className="tab-content help-center-tab-cont">
                    {/* <div id="a" className="tab-pane active"></div>
                    <div id="b" className="tab-pane fade"></div>
                    <div id="c" className="tab-pane fade"></div> */}
                    {item?.attributes?.faq?.map(
                      (faqItem: any, faqIndex: any) => (
                        <div
                          key={faqIndex}
                          id={'q' + item.id + faqItem.id}
                          className={`${
                            faqIndex === 0 ? 'tab-pane active' : 'tab-pane fade'
                          }`}
                        >
                          <h2>{faqItem.question}</h2>
                          <div
                            style={{ whiteSpace: 'pre-wrap' }}
                            dangerouslySetInnerHTML={{
                              __html: faqItem?.answer,
                            }}
                          />
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default HelpCenter
