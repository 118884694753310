import React, { useEffect } from 'react'
import useFetch from 'src/hooks/useFetch'

const AmlKycPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const { data, loading } = useFetch(`/aml-kyc-policy?populate=*`)
  return (
    <section>
      <div className="tab-content help-center-tab-cont policy-pages">
        <h1 className="policy-title">{data?.attributes.pageTitle}</h1>
        <div
          style={{ whiteSpace: 'pre-wrap', textAlign: 'justify' }}
          dangerouslySetInnerHTML={{
            __html: data?.attributes.pageText,
          }}
        />
      </div>
    </section>
  )
}

export default AmlKycPolicy
