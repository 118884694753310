import { Link } from "react-router-dom";

const HomepageAboutUs = (data: any) => {
  return (
    <div className="about-section content">
      <div className="abt-inside">
        <div className="abt-l">
          <div>{data?.data?.fourthSectionHeading}</div>
        </div>
        <div className="abt-r">
          {data?.data?.fourthSectionText.map((item: any) => (
            <p key={item.id}>{item.fourthSectionParagraph}</p>
          ))}
        </div>
      </div>
      <Link to="/about-us" className="purp-btn hug-h">
        Learn more <img src="/img/arrow-right.svg" alt="Arrow Right" />
      </Link>
    </div>
  );
};

export default HomepageAboutUs;
