import React, { useEffect } from 'react'

import CoinTypes from 'src/components/CoinTypes'
import Advantages from 'src/components/Advantages'
import Footer from 'src/components/Footer'
import { Outlet } from 'react-router-dom'
import useFetch from 'src/hooks/useFetch'
import HomepageAboutUs from './HomepageAboutUs'
import Header from './Header'

function Main() {
  const { data, loading } = useFetch(`/homepage?populate=*`)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      <div className="header-bg-hug">
        <div className="content">
          <Header showMain={true} />
          <div className="exchange-box-outside">
            <h1>{data?.attributes.firstSectionHeading}</h1>
            <div className="sub-title title-w">
              {data?.attributes.firstSectionText}
            </div>
            <div className="exchange-box-border shape-box">
              <div className="exchange-box shape-box-ins">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
      <section>
        <CoinTypes data={data?.attributes} />
        <Advantages data={data?.attributes} />
        <HomepageAboutUs data={data?.attributes} />
      </section>
      <Footer />
    </div>
  )
}

export default Main
