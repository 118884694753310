import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { REACT_APP_APPINSIGHTS_INSTRUMENTATIONKEY } from "../envVariables";
import {
  ReactPlugin,
  withAITracking,
} from "@microsoft/applicationinsights-react-js";

const reactPlugin = new ReactPlugin();
const ai = new ApplicationInsights({
  config: {
    connectionString: `${REACT_APP_APPINSIGHTS_INSTRUMENTATIONKEY}`,
    enableAutoRouteTracking: true,
    extensions: [reactPlugin],
  },
});
ai.loadAppInsights();

export default (Component: any) => withAITracking(reactPlugin, Component);

export const appInsights = ai.appInsights;
