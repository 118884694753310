import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios  from "axios";
import { getAsync, postAsync, putAsync } from "src/api/httpClient";
import { STANDARD_ERROR_MESSAGE } from "src/constants/constants";
import {Client, InitClient, UpdateClient, UpdateClientWithPassport} from "src/models/RegisterClient";

type InitialState = {
  loading: boolean;
  client: Client | null;
  error: string | null;
  lastUpdate: string | null;
};

const initialState: InitialState = {
  loading: false,
  client: null,
  error: null,
  lastUpdate: null,
};

export const getClient = createAsyncThunk(
  "clients/getClient",
  async (email: string) => {
    return await getAsync<Client>(`clients?email=${email}`);
  }
);

export const createClient = createAsyncThunk(
  "clients/createClient",
  async (initClient: InitClient) => {
    return await postAsync<InitClient, Client>("clients", initClient);
  }
);

export const updateClient = createAsyncThunk(
  "clients/updateClient",
  async (updateClient: UpdateClient) => {
    return await putAsync<UpdateClient, any>("clients", updateClient);
  }
);

export const updateClientWithPassport = createAsyncThunk(
    "clients/updateClientWithPassport",
      async (updateClient: UpdateClientWithPassport) => {
        const form = new FormData();

        form.append("client", JSON.stringify({...updateClient, file: null}));
        form.append("passport", updateClient.file);

        try {
          const response = await axios.put("clients/with-passport",
              form,
              {
                headers: {"Content-Type": "multipart/form-data"},
                data: form
              })
          return { error: undefined, statusCode: response.status, data: response.data };
        } catch (error) {
          if (axios.isAxiosError(error)) {
            const errorMessage = error.response?.data?.message;
            return { error: errorMessage, statusCode: error.response?.status ?? 500, data: {} };
          } else {
            return {
              error: STANDARD_ERROR_MESSAGE,
              statusCode: 500,
              data: {},
            };
          }
        }
    }
)

const clientsSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {
    initClient: (state, action: PayloadAction<Client>) => {
      state.client = action.payload;
      state.lastUpdate = new Date().toString();
    },
    resetClient: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createClient.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(createClient.fulfilled, (state, action) => {
      state.loading = false;
      state.client = action.payload.data ?? null;
      state.error = null;
      state.lastUpdate = new Date().toString();
    });

    builder.addCase(createClient.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? STANDARD_ERROR_MESSAGE;
      state.lastUpdate = new Date().toString();
    });

    builder.addCase(getClient.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getClient.fulfilled, (state, action) => {
      state.loading = false;
      state.client = action.payload.data ?? null;
      state.error = null;
      state.lastUpdate = new Date().toString();
    });

    builder.addCase(getClient.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? STANDARD_ERROR_MESSAGE;
      state.lastUpdate = new Date().toString();
    });

    builder.addCase(updateClient.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(updateClient.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.lastUpdate = new Date().toString();
    });

    builder.addCase(updateClient.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? STANDARD_ERROR_MESSAGE;
      state.lastUpdate = new Date().toString();
    });

    builder.addCase(updateClientWithPassport.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(updateClientWithPassport.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.lastUpdate = new Date().toString();
      state.client = action.payload.data
    });

    builder.addCase(updateClientWithPassport.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? STANDARD_ERROR_MESSAGE;
      state.lastUpdate = new Date().toString();
    });
  },
});

export const { resetClient, initClient } = clientsSlice.actions;
export default clientsSlice.reducer;
